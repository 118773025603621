import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { RefBookInfo } from '../model/refBookInfo';
import { CreateResponse } from '../model/createResponse';

@Injectable({
  providedIn: 'root',
})
export class CreateRefbookService {
  // tslint:disable-next-line:variable-name
  _env: Environment = environment;

  constructor(private http: HttpClient) {}

  getRefBookByOid(guid: string): Observable<RefBookInfo> {
    return this.http.get<RefBookInfo>(
      `${this._env.baseUrl}/su_nsi/minzdrav/info_by_oid/${guid}`,
      {
        params: {
          checkInBD: 'true',
        },
      }
    );
  }

  postRefBookByOid(refBookInfo): Observable<any> {
    return this.http.post<any>(
      `${this._env.baseUrl}/su_nsi/minzdrav/create`,
      refBookInfo
    );
  }

  confirmRefBookSave(
    rbookCode: string,
    version: string
  ): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      `${this._env.baseUrl}/su_nsi/dicts/start_to_load_one_dict`,
      {
        rbookcode: rbookCode,
        version,
      }
    );
  }
}
