import { Component, OnInit } from '@angular/core';
import { CreateRefbookService } from '../../../../services/create-refbook.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Versions } from '../../../../model/versions';
import { RefBookField } from '../../../../model/refBookField';
import { RefBookInfo } from '../../../../model/refBookInfo';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-federal-refbook',
  templateUrl: './create-federal-refbook.component.html',
  styleUrls: ['./create-federal-refbook.component.css'],
  providers: [MessageService],
})
export class CreateFederalRefbookComponent implements OnInit {
  refBookOid: string;
  refBookForm: FormGroup;
  refBookVersion: Versions[];
  choosenRefbookVersion: Versions;
  refBookFields: RefBookField[];
  shortName: string;
  fullName: string;
  showLoad: boolean;
  showForm: boolean;
  createdRbooKCode: string;
  loading: boolean;

  get fieldMappingList() {
    return this.refBookForm.get('structure.fieldMappingList') as FormArray;
  }

  get showFieldMappingList() {
    return this.refBookForm.get('structure.showFieldMappingList') as FormArray;
  }

  get structure() {
    return this.refBookForm.get('structure') as FormGroup;
  }

  private static checkVarchar(dataType: string) {
    return dataType === 'VARCHAR' ? '1000' : '';
  }

  constructor(
    private createRefBookService: CreateRefbookService,
    private dialog: DynamicDialogRef,
    private messageService: MessageService
  ) {
    this.refBookOid = '';
    this.showForm = false;
    this.showLoad = false;
    this.refBookFields = [];
    this.refBookForm = new FormGroup({
      isFederal: new FormControl(1),
      oid: new FormControl(),
      comment: new FormControl(),
      selectedVersion: new FormControl(),
      groupId: new FormControl(5),
      structure: new FormGroup({
        schema: new FormControl('NSI'),
        linked: new FormControl(true),
        name: new FormControl(),
        pk: new FormControl(),
        fieldMappingList: new FormArray([]),
        showFieldMappingList: new FormArray([]),
        passport: new FormControl(),
        passportAsString: new FormControl(),
      }),
      versions: new FormControl(),
      rbookCode: new FormControl(),
      rbookName: new FormControl(),
    });
  }

  ngOnInit(): void { }

  getRefBookInfo() {
    this.showForm = false;
    this.showFieldMappingList.clear();
    if (this.refBookOid.trim() !== '') {
      this.showLoad = true;
      this.createRefBookService
        .getRefBookByOid(this.refBookOid.trim())
        .subscribe(
          (refBook) => {
            if (refBook.error) {
              alert(refBook.error);
              this.showLoad = false;
            }
            this.patchValue(refBook);
          },
          (err) => {
            this.showLoad = false;
            alert(err.message);
          },
          () => {
            this.showLoad = false;
            this.showForm = true;
          }
        );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: `Поле "Идентификатор" не заполнено`,
      });
    }
  }

  closeTab() {
    this.dialog.close();
  }

  sendRefBookInfo() {
    this.refBookForm.controls.selectedVersion.patchValue(
      this.choosenRefbookVersion.S_VERSION
    );
    this.refBookForm.controls.oid.patchValue(this.refBookOid);
    this.createRefBookService
      .postRefBookByOid(this.refBookForm.value)
      .subscribe((data) => {
        if (data.error) {
          this.messageService.add({
            severity: 'error',
            summary: `${data.error}`,
          });
        }
        if (data.status === 'ResultStatus(status=SUCCESS)') {
          this.createdRbooKCode = data.rbookcode;
          this.confirmCreate(data.rbookcode);
          this.showLoad = true;
          this.showForm = false;
        }
      });
  }

  private patchValue(refBook: RefBookInfo) {
    this.refBookFields = refBook.structure.passport.fields;
    this.refBookVersion = refBook.versions;
    this.fullName = refBook.structure.passport.fullName;
    this.shortName = refBook.structure.passport.shortName;
    this.structure.controls.pk.patchValue(refBook.structure.pk);
    this.structure.controls.passport.patchValue(refBook.structure.passport);
    this.structure.controls.passportAsString.patchValue(
      refBook.structure.passportAsString
    );
    this.refBookForm.controls.rbookName.patchValue(refBook.rbookName);
    this.refBookForm.controls.rbookCode.patchValue(refBook.rbookCode);
    this.refBookForm.controls.versions.patchValue(refBook.versions);

    refBook.structure.fieldMappingList.forEach((el) => {
      this.fieldMappingList.push(
        new FormGroup({
          fieldName: new FormControl(el.fieldName),
          fieldType: new FormControl(el.fieldType),
          sourceField: new FormControl(el.sourceField),
          length: new FormControl(el.length),
          alias: new FormControl(el.alias),
        })
      );
    });

    refBook.structure.passport.fields.forEach((x) => {
      this.showFieldMappingList.push(
        new FormGroup({
          field: new FormControl(x.field),
          alias: new FormControl(x.alias),
          dataType: new FormControl(x.dataType),
          infoSize: new FormControl(
            CreateFederalRefbookComponent.checkVarchar(x.dataType)
          ),
        })
      );
    });
  }

  private confirmCreate(rbookcode) {
    this.createRefBookService
      .confirmRefBookSave(rbookcode, this.choosenRefbookVersion.S_VERSION)
      .subscribe((result) => {
        if (result.status === 'SUCCESS') {
          this.dialog.close('success');
        }
      });
  }
}
