<div class="wrapper">
  <h3 class="position-absolute">Группы</h3>
  <header>
    <p-button label="Создать группу" (click)="createGroup()"></p-button>
  </header>
  <p-table
    [value]="groupList"
    [paginator]="true"
    currentPageReportTemplate="{currentPage} из {totalPages}"
    [rows]="10"
    [rowHover]="true"
    [rowsPerPageOptions]="[5, 10, 15]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Префикс</th>
        <th>Наименование</th>
        <th>Описание</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-table>
      <tr>
        <td>{{ table.prefix }}</td>
        <td>{{ table.name }}</td>
        <td>{{ table.description }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-toast></p-toast>
