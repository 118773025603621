<div *ngIf="showDownloadBlock; else elseBlock">
  <input
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    type="file"
    (change)="onFileChange($event)"
  />
  <button type="button" (click)="uploadFile()">Загрузить</button>
</div>
<ng-template #elseBlock>
  <form [formGroup]="refBookForm" (ngSubmit)="createRefBook()">
    <div class="inputs-block space-between">
      <label class="input-element">
        Группа
        <p-dropdown
          [(ngModel)]="group"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Выберите группу"
          [options]="groupDropdownOptions"
          optionLabel="name"
        ></p-dropdown>
      </label>
    <!--  <label class="input-element child-active">
        Идентификатор
        <input
          formControlName="oid"
          type="text"
          name="getByOid"
          class="p-inputtext p-component"
          pInputText
        />
      </label>-->
      <label class="input-element child-active flex-1">
        Версия
        <input
          formControlName="selectedVersion"
          placeholder="Выбрать версию..."
          pInputText
          type="text"
          pattern="\d+(\.\d)?"
          class="p-inputtext p-component"
        />
      </label>
    </div>
    <div class="inputs-block">
      <label class="input-element child-active flex-1">
        Наименование справочника
        <input
          formControlName="rbookName"
          type="text"
          class="p-inputtext p-component"
          pInputText
        />
      </label>
    </div>
    <ng-container formGroupName="structure">
      <div class="" formArrayName="fieldMappingList">
        <div
          *ngIf="refBookForm"
          class="refBook-fields inputs-block space-between"
        >
          <label class="input-element big-size">Наименование</label>
          <label class="input-element big-size">Псевдоним</label>
          <label class="input-element small-size">Тип</label>
          <label class="input-element small-size">Размер</label>
        </div>
        <ng-container
          *ngFor="let field of fieldMappingList.controls; index as i"
        >
          <div
            [formGroupName]="i"
            class="refBook-fields inputs-block space-between"
          >
            <label class="input-element big-size">
              <input
                formControlName="fieldName"
                type="text"
                class="p-inputtext p-component"
                pInputText
              />
            </label>
            <label class="input-element big-size">
              <input
                formControlName="alias"
                type="text"
                class="p-inputtext p-component"
                pInputText
              />
            </label>
            <label class="input-element small-size">
              <p-dropdown
                [ngStyle]="{ width: '100%' }"
                formControlName="fieldType"
                [options]="dataTypeOptions"
                optionLabel="label"
                optionValue="label"
                placeholder="выберите тип"
              >
              </p-dropdown>
            </label>
            <label class="input-element small-size">
              <input
                type="number"
                formControlName="length"
                class="p-inputtext p-component"
                pInputText
              />
            </label>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div class="inputs-block inputs-block buttons">
      <button
        type="button"
        class="p-button p-component"
        (click)="closeTab()"
        pButton
        label=" "
      >
        Отмена
      </button>
      <button class="p-button p-component" type="submit" pButton label=" ">
        Сохранить
      </button>
    </div>
  </form>
</ng-template>
<p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
