import { Component, OnDestroy, OnInit } from '@angular/core';
import { CertificatesService } from '../../../services/certificates.service';
import { Subscription } from 'rxjs';
import { MedicalDepartments } from '../../../model/medicalDepartments';
import { CertificatesContent } from '../../../model/certificatesContent';
import { environment } from '../../../../environments/environment';
import { UserAuthService } from '../../../services/user-auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css'],
  providers: [MessageService],
})
export class CertificatesComponent implements OnInit {
  getMoList: MedicalDepartments[];
  certificates: CertificatesContent[];
  filter = { page: 0, size: 30, mu: null, actual: false };
  actual: boolean;

  downloadURL = `${environment.baseUrl}/su_nsi/certificates/download/`;
  uploadURL = `${environment.baseUrl}/su_nsi/certificates/save`;
  displayDialog = false;
  idMos: number[];
  loading: boolean;

  constructor(
    private certificateService: CertificatesService,
    private authService: UserAuthService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getMoList = [];
    this.loading = true;
    this.certificateService.getMoList(this.authService.userIdRefMu).subscribe(
      (response) => {
        if (!Array.isArray(response)) {
          this.getMoList.push({
            idMu: response.idMu,
            nameMu: response.nameMu,
          });
        } else {
          this.getMoList = response;
        }
      },
      (error) => {
        console.log(error);
      },
      () => { }
    );

    this.getCertificates();
  }

  onFilterChanged() {
    this.filter.page = 0;
    this.getCertificates();
  }

  getCertificates() {
    this.certificateService.getCertificates(this.filter).subscribe((cert) =>
      this.certificates = cert.content
    );
  }

  onAddCertificate() {
    this.displayDialog = true;
  }

  onBeforeUpload(event) {
    const idMu = this.filter.mu.idMu;
    event.formData.append('idmu', idMu);
  }

  onUpload() {
    this.getCertificates();
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Файл успешно отправлен',
    });
  }

  onCancelUpload() {
    this.displayDialog = false;
  }

  onError($event) {
    this.messageService.add({
      severity: 'error',
      summary: `Ошибка`,
      detail: `${$event.error.error.message}`,
    });
  }
}
