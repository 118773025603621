import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupService } from '../../services/group.service';
import { Group } from '../../model/group';
import { Status } from 'src/app/model/status';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css'],
})
export class FiltersComponent implements OnInit {
  @Output() refBookForm = new EventEmitter<FormGroup>();
  @Input() isFederal: boolean;

  filtersForm = new FormGroup({
    refBookName: new FormControl(''),
    refBookId: new FormControl(''),
    refBookGroup: new FormControl(''),
    refBookStatus: new FormControl(''),
  });
  groupList: Group[];
  statusList: Status[];

  constructor(private getGroup: GroupService) { }

  ngOnInit(): void {
    this.getGroup.getGroupList().subscribe((groups) => {
      this.groupList = groups;
    });
    this.getGroup.getStatus().subscribe((status) => {
      this.statusList = status;
    });
  }

  onSubmit() {
    this.refBookForm.emit(this.filtersForm);
  }

  clearFilter(inputNumber: string) {
    switch (inputNumber) {
      case '0':
        this.refBookId.reset();
        this.onSubmit();
        break;
      case '1':
        this.refBookName.reset();
        this.onSubmit();
    }
  }

  get refBookId() {
    return this.filtersForm.get('refBookId') as FormControl;
  }

  get refBookName() {
    return this.filtersForm.get('refBookName') as FormControl;
  }
}
