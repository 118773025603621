<div *ngIf="showCard" class="wrapper">
  <div class="button-container">
    <button
      *ngIf="isOwn"
      #btn
      type="button"
      pButton
      icon="pi pi-bars"
      label="Действия"
      (click)="menu.toggle($event)"
    ></button>
    <p-slideMenu
      #menu
      [model]="items"
      [popup]="true"
      [viewportHeight]="160"
    ></p-slideMenu>
  </div>
  <form class="inputs" (submit)="saveUserForm()" [formGroup]="userForm">
    <div class="inputs-block">
      <label class="input-element input-login">
        Логин
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.login"
          [value]="userForm.value.login"
        />
      </label>
      <label class="input-element role-list">
        Роль
        <p-dropdown
          [readonly]="isEdit"
          placeholder="Выберите роль"
          [options]="chooseRole"
          formControlName="role"
          optionValue="name"
          optionLabel="alias"
          (onChange)="checkRole($event)"
          class="cursor-auto"
          [class.non-icon]="isEdit"
          [class.required-error]="requiredError"
        ></p-dropdown>
        <span *ngIf="requiredError" class="error-span"> Заполните поле </span>
      </label>
    </div>
    <label *ngIf="showMedOrgBlock" style="width: 100%;">
      Мед организация <span class="required">*</span>
      <p-dropdown
        [readonly]="isEdit"
        placeholder="Выберите медицинскую организацию"
        [options]="medOrgList"
        formControlName="idMos"
        optionValue="idMu"
        optionLabel="nameMu"
        class="cursor-auto"
        (onChange)="checkMos($event)"
        [class.non-icon]="isEdit"
        [class.required-error]="requiredErrorMos"
      ></p-dropdown>
      <span *ngIf="requiredErrorMos" class="error-span"> Заполните поле </span>
    </label>
    <div class="inputs-block">
      <label class="input-element">
        Фамилия
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.lastname"
          [value]="userForm.value.lastname"
        />
      </label>
      <label class="input-element">
        Имя
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.firstname"
          [value]="userForm.value.firstname"
        />
      </label>
      <label class="input-element">
        Отчество
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.middlename"
          [value]="userForm.value.middlename"
        />
      </label>
    </div>
    <div class="inputs-block">
      <label class="input-element">
        Организация
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.organisation"
          [value]="userForm.value.organisation"
        />
      </label>
      <label class="input-element">
        Электронная почта
        <input
          [readOnly]="isEdit"
          type="text"
          class="p-inputtext p-component cursor-auto"
          pInputText
          [formControl]="userForm.controls.email"
          [value]="userForm.value.email"
        />
      </label>
    </div>
    <div class="groups">
      <div class="groups-content">
        <h5>Группы</h5>
        <i (click)="addGroup()" class="pi pi-plus" *ngIf="!isEdit"></i>
      </div>
      <div
        class="groups-content groups-list"
        *ngFor="let group of showUser.groups"
      >
        {{ group.name }}
        <i class="pi pi-times" (click)="deleteUserGroup(group.id)" *ngIf="!isEdit"></i>
      </div>
    </div>
    <div class="inputs-block inputs-block--buttons">
      <button
        type="button"
        class="p-button p-component"
        (click)="closeTab()"
        pButton
        label=" "
        *ngIf="isOwn"
      >
        Очистить
      </button>
      <button class="p-button p-component" type="submit" pButton label=" " *ngIf="!isEdit">
        Сохранить
      </button>
    </div>
  </form>
</div>
<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
