<form class="inputs" (ngSubmit)="saveUserForm()" [formGroup]="userForm">
  <div class="inputs-block">
    <label class="input-element input-login">
      Логин
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.login"
      />
    </label>
    <label class="input-element role-list">
      Роль
      <p-dropdown
        placeholder="Выберите роль"
        (onChange)="checkRole($event)"
        [options]="chooseRole"
        [(ngModel)]="userRole"
        [ngModelOptions]="{ standalone: true }"
        optionLabel="alias"
      ></p-dropdown>
    </label>
  </div>
  <label *ngIf="showMedOrgBlock" style="width: 100%;">
    Мед организация
    <p-dropdown
      placeholder="Выберите медицинскую организацию"
      [options]="medOrgList"
      formControlName="idMos"
      optionValue="idMu"
      optionLabel="nameMu"
    ></p-dropdown>
  </label>
  <div class="inputs-block">
    <label class="input-element">
      Фамилия
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.lastname"
      />
    </label>
    <label class="input-element">
      Имя
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.firstname"
      />
    </label>
    <label class="input-element">
      Отчество
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.middlename"
      />
    </label>
  </div>
  <div class="inputs-block">
    <label class="input-element">
      Организация
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.organisation"
      />
    </label>
    <label class="input-element">
      Электронная почта
      <input
        type="text"
        class="p-inputtext p-component"
        pInputText
        [formControl]="userForm.controls.email"
      />
    </label>
  </div>
  <div class="groups">
    <div class="groups-content">
      <h5>Группы</h5>
      <i (click)="addGroup()" class="pi pi-plus"></i>
    </div>
    <div
      class="groups-content groups-list"
      *ngFor="let group of userGroups.value"
    >
      {{ group.name }}
      <i class="pi pi-times" (click)="deleteUserGroup(group.id)"></i>
    </div>
  </div>
  <div class="inputs-block inputs-block--buttons">
    <button
      type="button"
      class="p-button p-component click-button"
      pButton
      id="cancel"
      label=" "
    >
      Отмена
    </button>

    <button
      type="submit"
      class="p-button p-component click-button"
      pButton
      label=" "
      id="save"
    >
      Сохранить
    </button>
  </div>
</form>
