<div class="inputs-block space-between">
  <label class="input-element">
    Группа
    <input
      type="text"
      class="p-inputtext p-component"
      readonly
      placeholder="fed"
      pInputText
    />
  </label>
  <label class="input-element child-active">
    Идентификатор
    <input
      type="text"
      name="getByOid"
      class="p-inputtext p-component"
      pInputText
      [(ngModel)]="refBookOid"
      (keyup.enter)="getRefBookInfo()"
    />
  </label>
  <label class="input-element child-active">
    Версия
    <p-dropdown
      [ngModelOptions]="{ standalone: true }"
      optionLabel="S_VERSION"
      [options]="refBookVersion"
      [(ngModel)]="choosenRefbookVersion"
      placeholder="Выбрать версию..."
    ></p-dropdown>
  </label>
</div>
<p-progressSpinner *ngIf="showLoad"></p-progressSpinner>
<form *ngIf="showForm" [formGroup]="refBookForm" (ngSubmit)="sendRefBookInfo()">
  <div class="inputs-block">
    <label class="input-element child-active">
      Полное наименование справочника
      <input
        [(ngModel)]="fullName"
        type="text"
        class="p-inputtext p-component"
        pInputText
        [ngModelOptions]="{ standalone: true }"
        readonly
      />
    </label>
    <label class="input-element child-active">
      Краткое наименование справочника
      <input
        [(ngModel)]="shortName"
        type="text"
        class="p-inputtext p-component"
        pInputText
        [ngModelOptions]="{ standalone: true }"
        readonly
      />
    </label>
  </div>
  <h5>Структура справочника</h5>
  <ng-container formGroupName="structure">
    <div class="" formArrayName="showFieldMappingList">
      <div
        *ngIf="refBookForm"
        class="refBook-fields inputs-block space-between"
      >
        <label class="input-element big-size">Наименование</label>
        <label class="input-element big-size">Псевдоним</label>
        <label class="input-element small-size">Тип</label>
        <label class="input-element small-size">Размер</label>
      </div>
      <ng-container
        *ngFor="let field of showFieldMappingList.controls; index as i"
      >
        <div
          [formGroupName]="i"
          class="refBook-fields inputs-block space-between"
        >
          <label class="input-element big-size">
            <input
              [readOnly]="true"
              formControlName="field"
              type="text"
              class="p-inputtext p-component"
              pInputText
            />
          </label>
          <label class="input-element big-size">
            <input
              [readOnly]="true"
              formControlName="alias"
              type="text"
              class="p-inputtext p-component"
              pInputText
            />
          </label>
          <label class="input-element small-size">
            <input
              [readOnly]="true"
              formControlName="dataType"
              type="text"
              class="p-inputtext p-component"
              pInputText
            />
          </label>
          <label class="input-element small-size">
            <input
              [readOnly]="true"
              formControlName="infoSize"
              type="text"
              class="p-inputtext p-component"
              pInputText
            />
          </label>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <h5>
    Описание справочника
  </h5>
  <label>
    <textarea
      formControlName="comment"
      style="width: 100%;"
      rows="5"
      cols="30"
      pInputTextarea
      autoResize="true"
      readonly
    ></textarea>
  </label>
  <div class="inputs-block inputs-block buttons">
    <button
      type="button"
      class="p-button p-component"
      (click)="closeTab()"
      pButton
      label=" "
    >
      Отмена
    </button>
    <button class="p-button p-component" type="submit" pButton label=" ">
      Сохранить
    </button>
  </div>
</form>

<p-toast></p-toast>
