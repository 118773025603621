<h5>
  OID
</h5>
<p *ngFor="let primary of codesPrimary">{{ primary.value }}</p>
<div *ngIf="showOid" class="OID">
  <h5>
    Дополнительный OID
  </h5>
  <p *ngFor="let other of codesOther">{{ other.value }}</p>
</div>
<hr />
<ng-container *ngIf="passportView">
  <h5>
    Полное наименование справочника
  </h5>
  <p>{{ passportView.fullName }}</p>
  <h5>
    Краткое наименование справочника
  </h5>
  <p>
    {{ passportView.shortName }}
  </p>
  <h5>
    Текущая версия
  </h5>
  <p>
    {{ passportView.version }}
  </p>
  <h5>Дата публикации текущей версии справочника</h5>
  <p>{{ passportView.publishDate }}</p>
  <h5>Количество записей в текущей версии справочника</h5>
  <p>{{ passportView.rowsCount }}</p>
</ng-container>

<h5>
  Структура справочника
</h5>
<p *ngFor="let field of passportFields">
  {{ field.field }}, {{ field.alias }}, {{ field.dataType }}
</p>
