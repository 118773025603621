import { Component, OnInit } from '@angular/core';
import { UploadFileService } from '../../../../services/upload-file.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GroupService } from '../../../../services/group.service';
import { Group } from '../../../../model/group';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserAuthService } from '../../../../services/user-auth.service';
import { UserGroup } from '../../../../model/userGroup';
import { filter } from 'rxjs/operators';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-create-regional-refbook',
  templateUrl: './create-regional-refbook.component.html',
  styleUrls: ['./create-regional-refbook.component.css'],
})
export class CreateRegionalRefbookComponent implements OnInit {
  private selectedFile: File;
  fileName: string;
  refBookForm: FormGroup;
  showDownloadBlock: boolean;
  groupDropdownOptions: UserGroup[] = [];
  dataTypeOptions: { label: string; }[] = [
    { label: 'NUMBER' },
    { label: 'STRING' },
    { label: 'DATE' },
  ];
  showForm: boolean;
  group: Group;
  isLoading: boolean;

  private sub: Subscription = new Subscription();
  groupList: Group[];

  constructor(
    private upload: UploadFileService,
    private refBookGroup: GroupService,
    private dialog: DynamicDialogRef,
    public authService: UserAuthService,
  ) {
    this.refBookForm = new FormGroup({
      isFederal: new FormControl(0),
      oid: new FormControl(),
      comment: new FormControl(),
      selectedVersion: new FormControl(),
      groupId: new FormControl(''),
      structure: new FormGroup({
        schema: new FormControl('NSI'),
        fieldMappingList: new FormArray([]),
      }),
      versions: new FormControl(),
      rbookCode: new FormControl(),
      rbookName: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      if (user.role.name === 'ROLE_ADMIN') {
        this.sub.add(this.refBookGroup.getGroupList().subscribe((groups) => {
          groups.forEach((group) => {
            if (group.id !== 5) {
              this.groupDropdownOptions.push({
                id: group.id,
                name: group.name,
              });
            }
          });
        }));
      }else{
        user.groups.forEach((group) => {
          if (group.id !== 5) {
            // this.groupDropdownOptions = [...this.groupDropdownOptions, group];
            this.groupDropdownOptions.push({
              id: group.id,
              name: group.name,
            });
          }
        });
      }
    });

    this.showDownloadBlock = true;
    this.showForm = false;
  }
  onFileChange($event) {
    this.selectedFile = $event.target.files[0];
    this.fileName = $event.target.files[0].name;
  }

  uploadFile() {
    this.isLoading = true;
    this.upload.uploadExcelFile(this.selectedFile).subscribe(
      (res) => {
        this.authService.getCurrentUser().subscribe((user) => {
            console.log(user);
        });
      },
      (error) => { },
      () => this.getColumns(this.fileName)
    );
  }

  get fieldMappingList() {
    return this.refBookForm.get('structure.fieldMappingList') as FormArray;
  }

  getColumns(fileName: string) {
    this.upload.getColumns(fileName).subscribe(
      (columns) => {
        columns.forEach((column) => {
          this.fieldMappingList.push(
            new FormGroup({
              fieldName: new FormControl(column),
              sourceField: new FormControl(column),
              alias: new FormControl(),
              fieldType: new FormControl(),
              length: new FormControl(),
            })
          );
        });
      },
      (error) => { },
      () => {
        this.showDownloadBlock = false;
        this.showForm = true;
        this.isLoading = false;
      }
    );
  }

  closeTab() {
    this.dialog.close();
  }

  get groupId() {
    return this.refBookForm.get('groupId') as FormControl;
  }

  get selectedVersion() {
    return this.refBookForm.get('selectedVersion') as FormControl;
  }

  createRefBook() {
    this.isLoading = true;
    this.groupId.patchValue(this.group.id);
    const floatVersion = Number.parseFloat(this.selectedVersion.value);
    this.selectedVersion.setValue(floatVersion);
    this.upload.sendRefBook(this.refBookForm.value).subscribe((response) => {
      if (response.error) {
        this.isLoading = false;
        alert(response.error);
      }
      this.loadDict(response.rbookcode);
    });
  }

  private loadDict(rbookcode: string) {
    this.upload
      .loadDictInfo(
        this.fileName,
        this.refBookForm.value.selectedVersion,
        rbookcode
      )
      .subscribe((response) => {
        this.dialog.close(response);
      });
  }
}
