<form (ngSubmit)="createGroup()" [formGroup]="groupForm">
  <div class="inputs-block">
    <label class="input-element">
      <span class="">Префикс <span class="red-star">*</span></span>
      <input
        formControlName="prefix"
        type="text"
        pInputText
        class="p-component p-inputtext"
      />
    </label>
    <label class="input-element">
      <span class="">Наименование <span class="red-star">*</span></span>
      <input
        formControlName="name"
        type="text"
        class="p-component p-inputtext"
      />
    </label>
  </div>
  <label class="input-element">
    Описание
    <textarea
      formControlName="description"
      style="width: 100%; height: 300px;"
      pInputTextarea
    ></textarea>
  </label>
  <div class="inputs-block inputs-block--buttons">
    <button
      type="button"
      class="p-button p-component click-button"
      pButton
      id="cancel"
      label=" "
      (click)="closeWindow()"
    >
      Отмена
    </button>

    <button
      type="submit"
      class="p-button p-component click-button"
      pButton
      label=" "
      id="save"
    >
      Сохранить
    </button>
  </div>
</form>

<p-toast></p-toast>
