import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/User.service';
import { User } from '../../../../model/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Role } from '../../../../model/role';
import { AddGroupToUserComponent } from '../add-group-to-user/add-group-to-user.component';
import { Group } from '../../../../model/group';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { MedicalDepartments } from '../../../../model/medicalDepartments';
import {element} from "protractor";

interface MenuOption {
  label: string;
  icon: string;
  command: () => void;
}

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css'],
  providers: [MessageService, DialogService],
})
export class UserCardComponent implements OnInit {
  showUser: User;
  userForm: FormGroup;
  message: string;
  items: MenuOption[];
  chooseRole: Role[] = [];
  userRole: Role;
  selectedMos: any;
  isEdit: boolean;
  ref: DynamicDialogRef;
  showCard: boolean;
  showLoader: boolean;
  showMedOrgBlock: boolean;
  medOrgList: MedicalDepartments[];
  isOwn: boolean;
  public requiredError = false;
  public requiredErrorMos = false;

  constructor(
    public config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private createWindow: DialogService,
    private user: UserService,
    private showMessage: MessageService
  ) {
    this.isOwn = this.config.data.own;
    this.items = [
      {
        label: 'Редактировать',
        icon: 'pi pi-fw pi-pencil',
        command: () => this.editUserCard(),
      },
      {
        label: 'Удалить',
        icon: 'pi pi-fw pi-trash',
        command: () => this.deleteUser(),
      },
    ];
    this.chooseRole = [
      {
        name: 'ROLE_MANAGER',
        alias: 'Менеджер',
      },
      {
        name: 'ROLE_ADMIN',
        alias: 'Администратор',
      },
  /*    {
        name: 'ROLE_READER',
        alias: 'Пользователь',
      },*/
      {
        name: 'ROLE_ADMIN_MO',
        alias: 'Администратор МО',
      },
    ];
    this.isEdit = true;
  }

  ngOnInit(): void {
    this.showMedOrgBlock = false;
    this.showCard = false;
    this.showLoader = true;
    this.userForm = new FormGroup({
      firstname: new FormControl(),
      lastname: new FormControl(),
      middlename: new FormControl(),
      login: new FormControl(),
      role: new FormControl(),
      idMos: new FormControl([Validators.required]),
      organisation: new FormControl(),
      email: new FormControl(),
      guid: new FormControl(),
      groups: new FormControl(),
    });

    this.user.getMedicalDepartments().subscribe((medList) => {
      this.medOrgList = medList;
    });

    this.user.getUser(this.config.data.guid).subscribe(
      (user) => {
        this.showUser = user;
        this.userForm.controls.firstname.setValue(user.firstname);
        this.userForm.controls.lastname.setValue(user.lastname);
        this.userForm.controls.middlename.setValue(user.middlename);
        this.userForm.controls.login.setValue(user.login);
        this.userForm.controls.role.setValue(user.role.name);
        this.userRole = user.role;
        this.userForm.controls.organisation.setValue(user.organisation);
        this.userForm.controls.email.setValue(user.email);
        this.userForm.controls.guid.setValue(user.guid);
        this.userForm.controls.groups.setValue(user.groups);
        if (user.idMos !== null){
          this.userForm.controls.idMos.setValue(user.idMos[0]);
        }else{
          this.userForm.controls.idMos.setValue(user.idMos);
        }
      },
      (error) => { },
      () => {
        this.showCard = true;
        this.showLoader = false;
        if (this.userRole.name === 'ROLE_ADMIN_MO') {
          this.showMedOrgBlock = true;
        }
      }
    );
  }

  saveUserForm() {
    const roleValue = this.chooseRole.filter((elem) => elem.name === this.userForm.controls.role.value);
    let mosValid = false;
    this.userForm.controls.role.setValue(roleValue[0]);
    this.userForm.controls.groups.setValue(this.showUser.groups);
    if (this.showMedOrgBlock) {
      this.userForm.controls.idMos.setValue([this.userForm.controls.idMos.value]);
    } else {
      this.userForm.controls.idMos.setValue(null);
    }
    // проверка на пустое поле мед.орг. у пользователя Администратор МО, если пустое то выводим сообщение о том что надо заполнить
    if (this.userForm.controls.role.value.name === 'ROLE_ADMIN_MO') {
      if (this.userForm.controls.idMos.value[0] === null) {
        this.requiredError = true;
        this.requiredErrorMos = true;
      } else {
        mosValid = true;
      }
    } else {
      mosValid = true;
    }
    if (mosValid) {
      this.user
        .saveUserCardInfo(this.config.data.guid, this.userForm.value)
        .subscribe(
          (data) => {
            if (data.result === 'success') {
              this.message = 'saved';
              this.dialog.close(this.message);
            }
          },
          (error) => {
            console.log(error.error.message);
          }
        );
    }
  }

  deleteUser() {
    this.user.deleteUser(this.config.data.guid).subscribe((data) => {
      if (data.result === 'success') {
        this.message = 'deleted';
        this.dialog.close(this.message);
      } else {
        this.showMessage.add({
          severity: 'error',
          summary: data.message,
        });
      }
    });
  }

  get groups() {
    return this.userForm.controls.groups.value;
  }

  addGroup() {
    this.ref = this.createWindow.open(AddGroupToUserComponent, {
      width: '20%',
      header: 'Выберите группы',
      data: {
        existGroups: this.showUser.groups,
      },
    });
    this.ref.onClose.subscribe(
      (groups: Group[]) => {
        this.userForm.controls.groups.setValue(groups);
        groups.forEach((group) => this.patchGroupValue(group));
      }
    );
  }

  deleteUserGroup(id: number) {
    const idIndex = this.showUser.groups.findIndex((x) => x.id === id);
    this.showUser.groups.splice(idIndex, 1);
    for (let i = 0; this.userForm.controls.groups.value.length > i; i++) {
      if (this.userForm.value.groups[i].id === id) {
        this.userForm.value.groups.splice(i, 1);
      }
    }
  }

  private editUserCard() {
    this.isEdit = !this.isEdit;
  }

  closeTab() {
    this.dialog.close();
  }

  private patchGroupValue(group: Group) {
    this.showUser.groups.push({
      id: group.id,
      name: group.name,
    });
  }

  checkRole($event: any) {
    this.requiredError = false;
    this.showMedOrgBlock = $event.value === 'ROLE_ADMIN_MO';
    if ($event.value === 'ROLE_ADMIN_MO'){
      this.requiredErrorMos = true;
    }
  }

  checkMos($event: any) {
    this.requiredErrorMos = false;
    this.selectedMos = this.medOrgList.filter((elem) => elem.idMu === $event.value);
    this.userForm.controls.idMos.setValue(this.selectedMos[0].idMu);
  }
}
