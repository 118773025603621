<div class="wrapper">
  <h3>Региональные справочники</h3>
  <app-filters
    [isFederal]="false"
    (refBookForm)="filterApply($event)"
  ></app-filters>
  <button
    *ngIf="
      authService.isAdmin() ||
      (authService.isManager() && authService.userGroups.length !== 0) || authService.isAdminMo()
    "
    pButton
    label=" "
    (click)="createRefbook()"
  >
    Создать справочник
  </button>
  <div class="table">
    <p-table
      [value]="updateParams"
      [rows]="10"
      class="table-container"
      [rowHover]="true"
      styleClass="custom-table"
    >
      <ng-template pTemplate="header">
        <tr class="table-header">
          <th>Код справочника</th>
          <th>Наименование</th>
          <th>Версия</th>
          <th>Группа</th>
          <th>Статус</th>
          <th>Комментарий</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-table>
        <tr
          [style.background-color]="
            table.existsNewVersions ? '#FFF9BF' : 'none'
          "
          [style.background-color]="table.status?.id === 3 ? '#F8B8B8' : 'none'"
          [routerLink]="['/regional', table.refBook.rbookCode]"
          (click)="activeRefbookService.activeGroup = table.groups[0].name; activeRefbookService.rBookName = table.refBook.tableName"
          class="table-body"
        >
          <td class="table-column">{{ table.refBook.rbookCode }}</td>
          <td class="table-column">{{ table.refBook.rbookName }}</td>
          <td class="table-column">{{ table.refBook.version }}
            <span *ngIf="table.publishDate; else nonSet">от {{ dateFormat(table.publishDate) }}</span>
            <ng-template #nonSet>от не указано </ng-template>
          </td>
          <td class="table-column" >
            <span *ngIf="table.groups && table.groups[0]?.name; else nonSetGroup">{{ table.groups[0].name }}</span>
            <ng-template #nonSetGroup> группа не указана </ng-template>
          </td>
          <td class="table-column">{{ table.status?.status }}</td>
          <td class="table-column">{{ table.refBook.comment }}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="pagination-wrapper">
      <div class="pagination-main">
        <p-paginator
          [rows]="10"
          [totalRecords]="totalRecords"
          (onPageChange)="setPage($event)"
          currentPageReportTemplate="{currentPage} из {totalPages}"
          [rowsPerPageOptions]="[10,30,50]"
          [showCurrentPageReport]="true"
        >
        </p-paginator>
      </div>
      <div class="pagination-total">
        <span>Всего записей: {{totalRecords}}</span>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
