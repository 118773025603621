<div *ngIf="!showLoader" class="">
  <div class="main-block">
    <label>Доступные версии</label>
    <p-dropdown
      style="position: absolute; right: 1px; padding-right: 15px;"
      [options]="optionList"
      optionLabel="version"
      optionValue="version"
      [(ngModel)]="selectedVersion"
      placeholder="выбрать версию"
      [class.ng-invalid]="unchosen"
      [class.ng-dirty]="unchosen"
      (onChange)="unchosen = false"
    ></p-dropdown>
  </div>
  <div class="button-block">
    <p-button (click)="sendVersion()" label="выгрузить"></p-button>
  </div>
</div>
<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
