import { Injectable } from '@angular/core';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MedicalDepartments } from '../model/medicalDepartments';
import { CertificatesData } from '../model/certificatesData';

@Injectable({
  providedIn: 'root',
})
export class CertificatesService {
  env: Environment = environment;

  constructor(private http: HttpClient) {}

  getMoList(mu?: any): Observable<any> {
    let filter = '';
    if (mu) {
      filter = `idMos=${mu}`;
    }
    return this.http.get<MedicalDepartments>(
      `${this.env.baseUrl}/su_nsi/certificates/medicalDepartments?${filter}`
    );
  }

  getCertificates({ page, size, mu, actual }): Observable<CertificatesData> {
    let filter = '';
    if (mu) {
      filter = `&idMu=${mu.idMu}`;
    }
    return this.http.get<CertificatesData>(
      `${this.env.baseUrl}/su_nsi/certificates?${filter}&page=${page}&size=${size}&actual=${actual}`
    );
  }
}
