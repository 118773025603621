import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentRefbookService {
  activeGroup: string;
  rBookName: string;
  isFederal: boolean;
  refbookOid: string;

  constructor() {}
}
