<form [formGroup]="filtersForm" (submit)="onSubmit()">
  <div class="filter-container">
    <span class="input-container p-input-icon-right">
      <i
        class="pi pi-times"
        style="cursor: pointer;"
        (click)="clearFilter('0')"
      ></i>
      <input
        class="input"
        *ngIf="isFederal"
        type="text"
        formControlName="refBookId"
        pInputText
        placeholder="Идентификатор"
      />
      <input
        class="input"
        *ngIf="!isFederal"
        type="text"
        formControlName="refBookId"
        pInputText
        placeholder="Код справочника"
      />
    </span>
    <span class="input-container p-input-icon-right">
      <i
        class="pi pi-times"
        style="cursor: pointer;"
        (click)="clearFilter('1')"
      ></i>
      <input
        class="input"
        type="text"
        formControlName="refBookName"
        pInputText
        placeholder="Наименование"
      />
    </span>
    <label class="ui-fluid" *ngIf="!isFederal">
      <p-dropdown
        [options]="groupList"
        optionLabel="name"
        optionValue="id"
        formControlName="refBookGroup"
        placeholder="Группа"
        [showClear]="true"
        (onChange)="onSubmit()"
      ></p-dropdown>
    </label>
    <label>
      <p-dropdown
        [options]="statusList"
        optionLabel="status"
        optionValue="id"
        formControlName="refBookStatus"
        placeholder="Статус"
        [showClear]="true"
        (onChange)="onSubmit()"
      ></p-dropdown>
    </label>
    <p-button
      label="Поиск"
      type="submit"
      [disabled]="!filtersForm.valid"
    ></p-button>
  </div>
</form>
