import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FederalComponent } from './components/federal/federal.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { HomeComponent } from './components/home/home.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { FiltersComponent } from './components/filters/filters.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RefbookComponent } from './components/refbook/refbook.component';
import { RegionalComponent } from './components/regional/regional.component';
import { DictSettingsComponent } from './components/shared/dict-settings/dict-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PassportComponent } from './components/shared/passport/passport.component';
import { AdministrationComponent } from './admin/components/administration/administration.component';
import { UserListComponent } from './admin/components/user-list/user-list.component';
import { GroupsComponent } from './admin/components/groups/groups.component';
import { UserCardComponent } from './admin/components/shared/user-card/user-card.component';
import { CreatUserComponent } from './admin/components/shared/creat-user/creat-user.component';
import { CreateFederalRefbookComponent } from './admin/components/shared/create-federal-refbook/create-federal-refbook.component';
import { CreateGroupComponent } from './admin/components/shared/create-group/create-group.component';
import { AddGroupToUserComponent } from './admin/components/shared/add-group-to-user/add-group-to-user.component';
import { CreateRegionalRefbookComponent } from './admin/components/shared/create-regional-refbook/create-regional-refbook.component';
import { LoginComponent } from './login/login.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { CardModule } from 'primeng/card';
import { DownloadVersionComponent } from './components/shared/download-version/download-version.component';
import { RefbookEditorComponent } from './components/refbook-editor/refbook-editor.component';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { CertificatesComponent } from './admin/components/certificates/certificates.component';
import { ConfirmationComponent } from './components/shared/confirmation/confirmation.component';
import { DownloadRefbookComponent } from './components/shared/download-refbook/download-refbook.component';
import { NgDynamicBreadcrumbModule} from 'ng-dynamic-breadcrumb';

@NgModule({
  declarations: [
    AppComponent,
    FederalComponent,
    MainLayoutComponent,
    HomeComponent,
    FiltersComponent,
    RefbookComponent,
    RegionalComponent,
    DictSettingsComponent,
    PassportComponent,
    AdministrationComponent,
    UserListComponent,
    GroupsComponent,
    UserCardComponent,
    CreatUserComponent,
    CreateFederalRefbookComponent,
    CreateGroupComponent,
    AddGroupToUserComponent,
    CreateRegionalRefbookComponent,
    LoginComponent,
    DownloadVersionComponent,
    RefbookEditorComponent,
    CertificatesComponent,
    ConfirmationComponent,
    DownloadRefbookComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    DropdownModule,
    BrowserAnimationsModule,
    MenubarModule,
    ButtonModule,
    HttpClientModule,
    TableModule,
    PaginatorModule,
    InputTextModule,
    TooltipModule,
    BreadcrumbModule,
    SelectButtonModule,
    ReactiveFormsModule,
    MenuModule,
    CardModule,
    DataViewModule,
    FileUploadModule,
    ToastModule,
    SlideMenuModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    CheckboxModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    SlideMenuModule,
    ButtonModule,
    ProgressSpinnerModule,
    TableModule,
    MenuModule,
    InputTextareaModule,
    CheckboxModule,
    RippleModule,
    ConfirmDialogModule,
    DialogModule,
    CommonModule,
    NgDynamicBreadcrumbModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
