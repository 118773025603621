import { Component, OnInit } from '@angular/core';
import { UploadFileService } from '../../../services/upload-file.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
})
export class AdministrationComponent implements OnInit {
  selectedFile: File;

  constructor(private upload: UploadFileService) { }

  ngOnInit(): void { }

  onFileChange($event) {
    this.selectedFile = $event.target.files[0];
  }

  uploadFile() {
    this.upload.uploadExcelFile(this.selectedFile).subscribe((res) => { });
  }
}
