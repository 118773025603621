import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Passport } from '../model/passport';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetPassportService {
  // tslint:disable-next-line:variable-name
  _env: Environment = environment;
  constructor(private http: HttpClient) {}

  getPassport(rBookName: string): Observable<Passport> {
    return this.http
      .get<Passport>(
        `${this._env.baseUrl}/su_nsi/dicts/data/${rBookName}/passport`
      )
      .pipe(share());
  }
}
