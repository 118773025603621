import {Component, OnInit, ViewChild} from '@angular/core';
import { GetTablesService } from '../../services/get-tables.service';
import { CreateRegionalRefbookComponent } from '../../admin/components/shared/create-regional-refbook/create-regional-refbook.component';
import { FormGroup } from '@angular/forms';
import { UserAuthService } from '../../services/user-auth.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import { CurrentRefbookService } from '../../services/current-refbook.service';
import { RefBookView } from 'src/app/model/reference';
import {Paginator} from "primeng/paginator";

@Component({
  selector: 'app-regional',
  templateUrl: './regional.component.html',
  styleUrls: ['./regional.component.css'],
  providers: [DialogService, DynamicDialogRef,  MessageService],
})
export class RegionalComponent implements OnInit {
  @ViewChild('p') paginator: Paginator;
  updateParams: RefBookView[];
  private ref: DynamicDialogRef;
  totalRecords: number;
  first: number;
  limit = 10;
  offset = 1;
  filterElem: any;
  constructor(
    private getTable: GetTablesService,
    private dialogService: DialogService,
    public authService: UserAuthService,
    private message: MessageService,
    public activeRefbookService: CurrentRefbookService
  ) { }

  ngOnInit(): void {
    this.getTable.getFederalTable(0, this.limit, this.offset).subscribe((rows) => {
      this.updateParams = rows.refBookViewList;
      this.totalRecords = rows.rowCount;
    });
  }

  createRefbook() {
    this.ref = this.dialogService.open(CreateRegionalRefbookComponent, {
      width: '70%',
      header: 'Создание справочника',
    });

    this.ref.onClose.subscribe((result) => {
      if (result.value === true) {
        this.message.add({
          severity: 'success',
          summary: 'Создание справочника',
          detail: 'Файл обрабатывается. Вы можете продолжить работу с системой.',
        });
      }
    });
  }

  filterApply($event: FormGroup) {
    this.filterElem = $event;
    this.getTable
      .getRegionalTable(
        0,
        this.limit,
        this.offset,
        $event.value.refBookName,
        $event.value.refBookId,
        $event.value.refBookGroup,
        $event.value.refBookStatus
      )
      .subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
        this.paginator.changePageToFirst($event);
      });
  }

  setPage(event: any): void {
    if (this.filterElem !== undefined) {
      this.getTable.getFederalTable(
        0,
        event.rows,
        (event.first / event.rows + 1),
        this.filterElem.value.refBookName,
        this.filterElem.value.refBookId,
        this.filterElem.value.refBookGroup,
        this.filterElem.value.refBookStatus,
      ).subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
      });
    }else{
      this.getTable.getFederalTable(0, event.rows, (event.first / event.rows + 1)).subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
      });
    }
  }

  dateFormat(str: string): any {
    return `${('0' + new Date(str).getDate()).slice(-2)}.${('0' + (new Date(str).getMonth() + 1)).slice(-2)}.${new Date(str).getFullYear()}`;
  }
}
