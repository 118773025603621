<div class="container container-settings">
  <div class="container passport-block">
    <p-button
      icon="pi pi-id-card"
      label="Паспорт"
      class="element button button-passport"
      (click)="showPassport()"
    ></p-button>

    <button
      type="button"
      pButton
      icon="pi pi-bars"
      label="Действия"
      (click)="menu.toggle($event)"
    ></button>

    <p-menu #menu [popup]="true" [model]="actions"></p-menu>
  </div>

  <div class="container version-block">
    <div class="element element__version">
      <label class="label" for="version">Версия</label>
      <p-dropdown
        [(ngModel)]="version"
        (onChange)="versionChanger($event)"
        [options]="versionList | async"
        name="version"
        id="version"
        class="version"
      >
      </p-dropdown>
    </div>

    <p-button (click)="filterSwitcher.emit()"
      ><i class="pi pi-filter"></i
    ></p-button>
  </div>
</div>

<p-toast></p-toast>
<p-dialog></p-dialog>
