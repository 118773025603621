import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { RawDictionary } from '../model/rawDictionary';
import { DictionaryView } from '../model/dictionaryView';
import { catchError } from 'rxjs/operators';
import { RefBookEditData } from '../model/refBookEditData';
import { Version } from '../model/versions';

@Injectable({
  providedIn: 'root',
})
export class GetRefbookService {
  // tslint:disable-next-line:variable-name
  _env: Environment = environment;
  params: HttpParams;

  constructor(private http: HttpClient) { }

  getDict(rBookName): Observable<RawDictionary> {
    return this.http.get<RawDictionary>(
      `${this._env.baseUrl}/su_nsi/dicts/table_description/${rBookName}`
    );
  }
  getDictByOid(oid): Observable<RawDictionary> {
    return this.http.get<RawDictionary>(
      `${this._env.baseUrl}/su_nsi/dicts/table_description_by_oid/${oid}`
    );
  }

  getVersionList(rBookName): Observable<string[]> {
    return this.http.get<string[]>(
      `${this._env.baseUrl}/su_nsi/dicts/data/${rBookName}/getVersionList`
    );
  }

  getDictInfo(
    rBookCode: string,
    limit?: number,
    offset?: number,
    filter?: Version,
  ): Observable<DictionaryView> {
    const params = { offset: `${offset}` };
    if (limit) {
      Object.assign(params, { limit: `${limit}` });
    }
    return this.http.post<DictionaryView>(
      `${this._env.baseUrl}/su_nsi/dicts/data/${rBookCode}/search`,
      filter,
      { params }
    );
  }

  exportRefbook(rBookCode, version?): Observable<any> {
    let objVersion = '';
    if (version) {
      objVersion = `?version=${version}`;
    }
    // @ts-ignore
    return this.http.get<any>(
      `${this._env.baseUrl}/su_nsi/dicts/data/${rBookCode}/export${objVersion}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  initEditing(rBookCode): Observable<any> {
    return this.http.get(
      `${this._env.baseUrl}/su_nsi/dicts/edit/init/${rBookCode}`
    ).pipe(
      catchError(error => {
        console.error('Edit init error', error);
        return EMPTY;
      })
    );
  }

  getMainData(tempTableCode, filters?): Observable<RefBookEditData[]> {
    let params = filters;
    if (params === undefined) {
      params = {};
    }
    return this.http.post<RefBookEditData[]>(
      `${this._env.baseUrl}/su_nsi/dicts/edit/data/${tempTableCode}/search?offset=0&limit=100`,
      params
    );
  }

  saveEditing(rBookCode): Observable<any> {
    return this.http.get(
      `${this._env.baseUrl}/su_nsi/dicts/edit/save/${rBookCode}`
    );
  }

  addRow(tempTableCode, body) {
    delete body.guid;
    delete body.version;
    const params = {
      action: 'add',
      data: {},
    };
    params.data = Object.assign({}, body);
    return this.http.post<string>(
      `${this._env.baseUrl}/su_nsi/dicts/edit/${tempTableCode}`,
      params
    );
  }

  deleteRow(tempTableCode, guid): Observable<any> {
    return this.http.post(
      `${this._env.baseUrl}/su_nsi/dicts/edit/${tempTableCode}`,
      {
        guid,
        action: 'delete',
      }
    );
  }

  editRow(tempTableCode, body) {
    const guid = body.guid;
    delete body.guid;
    delete body.version;
    const params = {
      guid: `${guid}`,
      action: 'edit',
      data: {},
    };
    params.data = Object.assign({}, body);
    return this.http.post(
      `${this._env.baseUrl}/su_nsi/dicts/edit/${tempTableCode}`,
      params
    );
  }

  deleteTempTable(tempTableCode: string): Observable<any> {
    return this.http.delete(
      `${this._env.baseUrl}/su_nsi/dicts/edit/${tempTableCode}`
    );
  }
}
