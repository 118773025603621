<div class="" *ngIf="showCard">
  <div *ngFor="let group of selectedGroup">
    <p-checkbox
      name="group2"
      value="group"
      [value]="group"
      [(ngModel)]="checkBoxedGroup"
    ></p-checkbox>
    <label style="margin-left: 5px;" [for]="group.name">{{ group.name }}</label>
  </div>
  <div class="buttons-container">
    <button
      type="button"
      class="p-button p-component click-button"
      pButton
      label=" "
      id="save"
      (click)="saveGroup()"
      style="margin-top: 15px;"
    >
      Сохранить
    </button>
  </div>
</div>
<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
