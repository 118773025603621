import { Component, OnInit } from '@angular/core';
import { GroupService } from '../../../../services/group.service';
import { UserGroup } from '../../../../model/userGroup';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-group-to-user',
  templateUrl: './add-group-to-user.component.html',
  styleUrls: ['./add-group-to-user.component.css'],
})
export class AddGroupToUserComponent implements OnInit {
  checkBoxedGroup: [] = [];
  selectedGroup: UserGroup[] = [];
  getGroupList: UserGroup[] = [];
  getGroupListID: number[] = [];
  showCard: boolean;
  showLoader: boolean;
  constructor(
    private dialog: DynamicDialogRef,
    private groupInfo: DynamicDialogConfig,
    private groupService: GroupService
  ) { }

  ngOnInit(): void {
    this.showCard = false;
    this.showLoader = true;

    if (this.groupInfo.data.existGroups) {
      this.groupInfo.data.existGroups.forEach((group) =>
        this.getGroupList.push(group)
      );
    }

    this.showCard = false;
    this.showLoader = true;
    this.groupService.getGroupList().subscribe(
      (data) => {
        const dataLength = data.length;
        this.getGroupList.forEach((group) =>
          this.getGroupListID.push(group.id)
        );
        if (this.getGroupListID.length > 0) {
          for (let i = 0; i < dataLength; i++) {
            if (!this.getGroupListID.includes(data[i].id)) {
              this.selectedGroup.push({ id: data[i].id, name: data[i].name });
            }
          }
        } else {
          data.forEach((x) =>
            this.selectedGroup.push({ id: x.id, name: x.name })
          );
        }
      },
      (error) => { },
      () => {
        this.showCard = true;
        this.showLoader = false;
      }
    );
  }

  saveGroup() {
    this.dialog.close(this.checkBoxedGroup);
  }
}
