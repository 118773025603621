import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FederalComponent } from './components/federal/federal.component';
import { RefbookComponent } from './components/refbook/refbook.component';
import { RegionalComponent } from './components/regional/regional.component';
import { AdministrationComponent } from './admin/components/administration/administration.component';
import { UserListComponent } from './admin/components/user-list/user-list.component';
import { GroupsComponent } from './admin/components/groups/groups.component';
import { AuthGuard } from './auth.guard';
import { RefbookEditorComponent } from './components/refbook-editor/refbook-editor.component';
import { CertificatesComponent } from './admin/components/certificates/certificates.component';

const routes: Routes = [
  { path: '', component: HomeComponent,
     data: {
       breadcrumb: [
         {
           label: 'Главная',
           url: ''
         }
       ]
     }
  },
  // Федеральные справочники
  {
    path: 'federal',
    component: FederalComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Федеральные',
          url: ''
        }
        ]
    }
  },
  {
    path: 'federal/:oid',
    component: RefbookComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Федеральные',
          url: 'federal'
        },
        {
          label: '{{customText}}',
          url: ''
        }
      ]
    }
  },
  {
    path: 'federal/:oid/edit',
    component: RefbookEditorComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Федеральные',
          url: 'federal'
        },
        {
          label: '{{customText}}',
          url: ''
        }
      ]
    }
  },
  // Региональные справочники
  {
    path: 'regional',
    component: RegionalComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Региональные',
          url: ''
        }
      ]
    }
  },
  {
    path: 'regional/:rbookCode/edit',
    component: RefbookEditorComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Региональные',
          url: 'regional'
        },
        {
          label: '{{customText}}',
          url: ''
        }
      ]
    }
  },
  {
    path: 'regional/:rbookCode',
    component: RefbookComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Региональные',
          url: 'regional'
        },
        {
          label: '{{customText}}',
          url: ''
        }
      ]
    }
  },
  // Админка
  {
    canActivate: [AuthGuard],
    path: 'admin',
    component: AdministrationComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Admin',
          url: ''
        }
      ]
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'admin/users',
    component: UserListComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Администрирование / Пользователи',
          url: '' // admin/users
        },

      ]
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'admin/groups',
    component: GroupsComponent,
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'Администрирование / Группы',
          url: '' // admin/groups
        },

      ]
    }
  },
  // Сертификаты
  {
    path: 'certificates',
    component: CertificatesComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        {
          label: 'Главная',
          url: '/'
        },
        {
          label: 'ЭЦП МО',
          url: '' // admin/groups
        },

      ]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
