import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Role } from '../../../../model/role';
import { UserService } from '../../../../services/User.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Group } from '../../../../model/group';
import { AddGroupToUserComponent } from '../add-group-to-user/add-group-to-user.component';
import { MedicalDepartments } from '../../../../model/medicalDepartments';

@Component({
  selector: 'app-creat-user',
  templateUrl: './creat-user.component.html',
  styleUrls: ['./creat-user.component.css'],
  providers: [DialogService],
})
export class CreatUserComponent implements OnInit {
  userForm: FormGroup;
  chooseRole: Role[];
  userRole: Role;
  medOrgList: MedicalDepartments[];
  medOrg: MedicalDepartments;
  showMedOrgBlock: boolean;

  constructor(
    private userService: UserService,
    private dialog: DynamicDialogRef,
    private addGroupService: DialogService
  ) {
    this.chooseRole = [
      {
        name: 'ROLE_MANAGER',
        alias: 'Менеджер',
      },
      {
        name: 'ROLE_ADMIN',
        alias: 'Администратор',
      },
    /*  {
        name: 'ROLE_READER',
        alias: 'Пользователь',
      },*/
      {
        name: 'ROLE_ADMIN_MO',
        alias: 'Администратор МО',
      },
    ];
  }

  ngOnInit(): void {
    this.showMedOrgBlock = false;
    this.userForm = new FormGroup({
      firstname: new FormControl(),
      lastname: new FormControl(),
      middlename: new FormControl(),
      login: new FormControl(),
      role: new FormControl(),
      organisation: new FormControl(),
      email: new FormControl(),
      groups: new FormArray([]),
      idMos: new FormControl(),
    });

    this.userService.getMedicalDepartments().subscribe((medList) => {
      this.medOrgList = medList;
    });
  }

  get userGroups() {
    return this.userForm.get('groups') as FormArray;
  }

  saveUserForm() {
    this.userForm.controls.role.patchValue(this.userRole);
    this.userService.createUser(this.userForm.value).subscribe(
      (answer) => {
        this.dialog.close(this.userForm.value);
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  addGroup() {
    const ref = this.addGroupService.open(AddGroupToUserComponent, {
      width: '20%',
      header: 'Выберите группы',
      data: {
        existGroups: this.userGroups.value,
      },
    });
    ref.onClose.subscribe(
      (groups: Group[]) => {
        groups.forEach((group) => {
          this.patchGroupValue(group);
        });
      },
      (error) => { },
      () => { }
    );
  }

  deleteUserGroup(id: number) {
    for (let i = 0; this.userGroups.value.length > i; i++) {
      if (this.userGroups.value[i].id === id) {
        this.userGroups.value.splice(i, 1);
      }
    }
  }

  private patchGroupValue(group: Group) {
    this.userGroups.push(
      new FormGroup({
        name: new FormControl(group.name),
        id: new FormControl(group.id),
      })
    );
  }

  checkRole($event: any) {
    this.showMedOrgBlock = $event.value.name === 'ROLE_ADMIN_MO';
  }
}
