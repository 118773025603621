<div class="wrapper">
  <h3>Федеральные справочники</h3>
  <app-filters
    [isFederal]="true"
    (refBookForm)="filterApply($event)"
  ></app-filters>
  <button
    *ngIf="
      userService.isAdmin() || (userService.isManager()) || userService.isAdminMo()
    "
    pButton
    label=" "
    (click)="createRefbook()"
  >
    Создать справочник
  </button>
  <div class="table">
    <p-table
      [value]="updateParams"
      [rows]="10"
      class="table-container"
      [rowHover]="true"
      styleClass="custom-table"
      [(first)]="first"
    >
      <ng-template pTemplate="header">
        <tr class="table-header">
          <th>Идентификатор</th>
          <th>Наименование</th>
          <th>Версия</th>
          <th>Группа</th>
          <th>Статус</th>
          <th>Комментарий</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-table>
        <tr
          [style.background-color]="
            table.existsNewVersions ? '#FFF9BF' : 'none'
          "
          [style.background-color]="table.status?.id === 3 ? '#F8B8B8' : 'none'"
          style="max-height: 200px"
          [routerLink]="['/federal', table.refBook.oid]"
          (click)="activeRefbookService.activeGroup = table.groups[0].name; activeRefbookService.rBookName = table.refBook.tableName;"
          class="table-body"
        >
          <td class="table-column">{{ table.refBook.oid }}</td>
          <td class="table-column">{{ nameFormat(table.refBook.rbookName) }}</td>
          <td class="table-column">{{ table.refBook.version }} <span *ngIf="table.publishDate">от {{  dateFormat(table.publishDate) }}</span></td>
          <td class="table-column">{{ table.groups[0].name }}</td>
          <td class="table-column">{{ table.status?.status }}</td>
          <!--Если есть нет сообщения об ошибке, проверить есть ли новая версия-->
          <td class="table-column">{{ table.errorMessage? table.errorMessage : table.existsNewVersions? 'Доступна новая версия' : ' ' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginator-center let-state">
        {{totalRecords}}
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <span>Всего записей: {{totalRecords}}</span>
      </ng-template>
    </p-table>
    <div class="pagination-wrapper">
      <div class="pagination-main">
        <p-paginator
            #p
          [rows]="10"
          [totalRecords]="totalRecords"
          (onPageChange)="setPage($event)"
          currentPageReportTemplate="{currentPage} из {totalPages}"
          [rowsPerPageOptions]="[10,30,50]"
          [showCurrentPageReport]="true"
        >
        </p-paginator>
      </div>
      <div class="pagination-total">
        <span>Всего записей: {{totalRecords}}</span>
      </div>
    </div>

  </div>
</div>
<div></div>
<p-toast></p-toast>
