import { Component, HostListener, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { GetRefbookService } from '../../services/get-refbook.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { RawDictionary } from '../../model/rawDictionary';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { CurrentRefbookService } from 'src/app/services/current-refbook.service';
import { RefBookEditData } from 'src/app/model/refBookEditData';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';

@Component({
  selector: 'app-refbook-editor',
  templateUrl: './refbook-editor.component.html',
  styleUrls: ['./refbook-editor.component.css'],
  providers: [ConfirmationService, MessageService],
})
export class RefbookEditorComponent implements OnInit {
  private rBookName: string;
  private tempTableCode: string;
  loading: boolean;
  columns: RawDictionary;
  filterForm: FormGroup;
  rows: RefBookEditData[];
  isFederal: boolean;
  filterSwitch: boolean;
  rowsForm: FormGroup;
  newObject: RefBookEditData = {};
  showDialog: boolean;
  createRow: boolean;
  showFilter: boolean;
  formApplier: {} = {};
  private touched: boolean;
  breadcrumb;
  isChange = true;
  valueChanged: any;

  constructor(
    private route: ActivatedRoute,
    private refBookService: GetRefbookService,
    private activeRefbookService: CurrentRefbookService,
    private confirmationService: ConfirmationService,
    private message: MessageService,
    private location: Location,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private router: Router,
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    if (this.touched) {
      event.returnValue = 'You have unfinished changes!';
    }
    this.refBookService
      .deleteTempTable(this.tempTableCode)
      .subscribe((answer) => { });
  }

  ngOnInit(): void {
    this.touched = true;
    this.showFilter = false;
    this.showDialog = false;
    this.loading = true;
    this.filterSwitch = false;
    this.filterForm = new FormGroup({
      filterFormArray: new FormArray([]),
    });

    this.rowsForm = new FormGroup({
      rowsFormArray: new FormArray([]),
    });
    // Получение наименования справочника
    this.rBookName = this.activeRefbookService.rBookName;
    const { rbookCode } = this.route.snapshot.params;

    this.refBookService.initEditing(rbookCode).subscribe(
      (data) => {
        this.getTableData(data.tempTableCode);
        this.tempTableCode = data.tempTableCode;
      },
      (error) => {
        this.showError(error);
      },
      () => { }
    );

    // Получение шапки таблицы
    this.refBookService.getDict(this.rBookName).subscribe((columns) => {
      this.columns = columns;

      // задаем имя в цепочке навигации
      this.breadcrumb =  {customText: this.columns.name};
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(this.breadcrumb);

      this.columns.fieldMappingList.forEach((x) => {
        x.fieldName = x.fieldName.toLowerCase();
        this.getFilterFormArray.push(
          new FormGroup({
            inputFieldName: new FormControl(x.fieldName),
            inputType: new FormControl(x.fieldType),
            inputValue: new FormControl(),
          })
        );
      });
    });
  }

  get getFilterFormArray() {
    return this.filterForm.get('filterFormArray') as FormArray;
  }

  private getTableData(tempTableCode: string) {
    this.refBookService.getMainData(tempTableCode).subscribe(
      (data) => {
        this.rows = data;
        this.newObject = Object.assign({}, this.rows[0]);
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  filterSwitcher() {
    this.filterSwitch = !this.filterSwitch;
  }

  addRow() {
    this.createRow = true;
    this.showDialog = true;
    this.newObject = {};
  }

  deleteRow(body: RefBookEditData) {
    this.refBookService
      .deleteRow(this.tempTableCode, body.guid)
      .subscribe((answer) =>
        this.message.add({
          severity: 'success',
          summary: 'Успешно',
          detail: 'Запись удалена',
        })
      );
    this.rows = this.rows.filter((val) => val.guid !== body.guid);
    this.isChange = false;
    this.showDialog = false;
  }

  editRow(body: RefBookEditData) {
    this.createRow = false;
    this.newObject = { ...body };
    this.showDialog = true;
  }

  hideDialog() {
    this.showDialog = false;
  }

  saveNewRow() {
    this.showDialog = false;
    this.rows.push(this.newObject);
    this.refBookService.addRow(this.tempTableCode, this.newObject).subscribe(
      (answer) => {
        this.isChange = false;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.rows.length; i++) {
          if (this.rows[i].guid === undefined) {
            this.rows[i].guid = answer;
            this.message.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Запись добавлена',
            });
            break;
          }
        }
      },
      (error) => {
        this.message.add({
          severity: 'error',
          summary: 'Ошибка при сохранении',
          detail: 'Запись не была добавлена',
        });
      },
      () => { }
    );
  }

  saveExistRow() {
    this.showDialog = false;
    // @ts-ignore
    if (this.valueChanged) {
      this.isChange = false;
    }
    this.rows[this.findIndexByGuid(this.newObject.guid)] = this.newObject;
    this.refBookService.editRow(this.tempTableCode, this.newObject).subscribe(
      (answer) => {
        this.message.add({
          severity: 'success',
          summary: 'Успешно',
          detail: 'Запись обновлена',
        });
      },
      (error) => { },
      () => { }
    );
  }
  isValueChange(e): void{
    if (e){
      this.valueChanged = true;
    }
  }

  private findIndexByGuid(guid: string) {
    let index = -1;
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i].guid === guid) {
        index = i;
        break;
      }
    }
    return index;
  }

  saveDict() {
    this.touched = false;
    this.refBookService
      .saveEditing(this.tempTableCode)
      .subscribe((answer) => { });
    if (this.isFederal){
      this.location.back();
    }else{
      this.router.navigateByUrl(`/regional/${this.rBookName}`)
        .then(() => {
          window.location.reload();
        });
    }
  }

  submitFilters() { }

  applyFilters() {
    for (const fieldName in this.getFilterFormArray.value) {
      if (this.getFilterFormArray.value[fieldName].inputValue) {
        const inputName = this.getFilterFormArray.value[fieldName]
          .inputFieldName;
        this.formApplier[inputName] = this.getFilterFormArray.value[
          fieldName
        ].inputValue;
      }
    }
    this.refBookService
      .getMainData(this.tempTableCode, this.formApplier)
      .subscribe((data) => {
        this.rows = data;
      });
  }

  clearFilter(fieldName: string, fieldNumber: number) {
    // @ts-ignore
    this.getFilterFormArray.controls[fieldNumber].controls.inputValue.reset();
    delete this.formApplier[fieldName];
    // tslint:disable-next-line:no-shadowed-variable
    for (const fieldName in this.getFilterFormArray.value) {
      if (this.getFilterFormArray.value[fieldName].inputValue) {
        const inputName = this.getFilterFormArray.value[fieldName]
          .inputFieldName;
        this.formApplier[inputName] = this.getFilterFormArray.value[
          fieldName
        ].inputValue;
      }
    }
    this.refBookService
      .getMainData(this.tempTableCode, this.formApplier)
      .subscribe((data) => {
        this.rows = data;
      });
  }

  private showError(error: any) {
    console.log(error.error);
    this.message.add({
      severity: 'error',
      summary: 'Ошибка',
      detail: `${error.error}`,
    });
  }

  goBack() {
    this.touched = true;
    this.refBookService
      .deleteTempTable(this.tempTableCode)
      .subscribe((answer) => { });
    this.location.back();
  }
}
