import { Component, OnInit } from '@angular/core';
import { GetTablesService } from '../../../services/get-tables.service';
import { CreateRefbookService } from '../../../services/create-refbook.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CurrentRefbookService } from '../../../services/current-refbook.service';
import { ExternalVersion } from 'src/app/model/externalVersion';

@Component({
  selector: 'app-download-version',
  templateUrl: './download-version.component.html',
  styleUrls: ['./download-version.component.css'],
})
export class DownloadVersionComponent implements OnInit {
  private rBookOid: string;
  private rBookCode: string;
  optionList: ExternalVersion[];
  selectedVersion: string;
  showLoader: boolean;
  unchosen: boolean;
  constructor(
    private getDict: GetTablesService,
    private refBookService: CreateRefbookService,
    private dialog: DynamicDialogRef,
    private currentRefBook: CurrentRefbookService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.rBookOid = this.currentRefBook.refbookOid;
    this.rBookCode = this.currentRefBook.rBookName;
    this.getDict.getFederalCodeByRbookCode(this.rBookOid).subscribe(
      (response) => {
        this.optionList = response;
      },
      (error) => { },
      () => {
        this.showLoader = false;
      }
    );
  }

  sendVersion() {
    if (this.selectedVersion === undefined) {
      return (this.unchosen = true);
    }
    this.getDict
      .checkVersion(this.rBookCode, this.selectedVersion)
      .subscribe((response) => {
        if (response.exists === false) {
          this.startToLoadOneDict();
        }
        if (response.exists === true) {
          alert('Версия уже существует');
        }
      });
  }

  private startToLoadOneDict() {
    this.refBookService
      .confirmRefBookSave(this.rBookCode, this.selectedVersion)
      .subscribe((response) => {
        if (response.status === 'SUCCESS') {
          this.dialog.close('success');
        } else {
          alert(response.error);
        }
      });
  }
}
