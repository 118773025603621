import { Component, OnInit } from '@angular/core';
import { GetPassportService } from '../../../services/get-passport.service';
import { ActivatedRoute } from '@angular/router';
import { Passport } from '../../../model/passport';
import { PassportCodes } from '../../../model/passportCodes';
import { PassportField } from '../../../model/passportField';
import { CurrentRefbookService } from '../../../services/current-refbook.service';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.css'],
})
export class PassportComponent implements OnInit {
  passportView: Passport;
  codesPrimary: PassportCodes[];
  codesOther: PassportCodes[];

  counter: number;
  showOid = true;
  passportFields: PassportField[];
  constructor(
    private passport: GetPassportService,
    private route: ActivatedRoute,
    private activeRefbookService: CurrentRefbookService
  ) {}

  ngOnInit(): void {
    const rBookName = this.activeRefbookService.rBookName;

    this.passport.getPassport(rBookName).subscribe((data) => {
      this.codesPrimary = data.codes.filter(
        (codes) => codes.type === 'TYPE_PRIMARY'
      );

      this.codesOther = data.codes.filter(
        (codes) => codes.type === 'TYPE_OTHER'
      );
      if (this.codesOther.length === 0) {
        this.showOid = false;
      }
      this.passportFields = data.fields;
      this.passportView = data;
    });
  }
}
