import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PassportComponent } from '../passport/passport.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem, MessageService } from 'primeng/api';
import { DownloadVersionComponent } from '../download-version/download-version.component';
import { UserAuthService } from '../../../services/user-auth.service';
import { Router } from '@angular/router';
import { GetRefbookService } from '../../../services/get-refbook.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { DownloadRefbookComponent } from '../download-refbook/download-refbook.component';
import { CurrentRefbookService } from '../../../services/current-refbook.service';
import { filter, share, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dict-settings',
  templateUrl: './dict-settings.component.html',
  styleUrls: ['./dict-settings.component.css'],
  providers: [DialogService, MessageService],
})
export class DictSettingsComponent implements OnInit {
  @Output() filterSwitcher = new EventEmitter<boolean>();
  @Output() newVersionEvent = new EventEmitter<string>();
  @Input() versionList: Observable<any>;
  @Input() passportExist: boolean;
  @Input() isFederal: boolean;
  @Input() showEditButton: boolean;
  @Input() refbookName: string;

  version: string;
  actions: MenuItem[];
  private ref: DynamicDialogRef;
  private rBookCode: string;
  private refBookRegionStatus: string;
  private tempTableName: string;

  constructor(
    private passport: DialogService,
    private message: MessageService,
    public authService: UserAuthService,
    private router: Router,
    private refBookEditor: GetRefbookService,
    private dialog: DialogService,
    private currentRefbook: CurrentRefbookService
  ) {
    this.showEditButton = false;
  }

  ngOnInit(): void {
    this.refBookRegionStatus = this.isFederal ? 'federal' : 'regional';
    this.rBookCode = this.currentRefbook.rBookName;
    this.initDropdownMenu();
  }

  private initDropdownMenu() {
    const optionDownload = {
      label: 'Скачать справочник',
      command: () => {
        this.downloadRefBook();
      },
    };

    const optionUpload = {
      label: 'Загрузить версию',
      command: () => {
        this.downloadVersion();
      },
    };
    const optionEdit = {
      label: 'Редактирование',
      command: () => {
        this.initEditing();
      },
    };

    this.actions = [{ items: [] }];
    if (this.authService.isAuth()) {
      this.actions[0].items.push(optionUpload);
    }

    if (this.authService.isAuth() && !this.isFederal) {
      this.actions[0].items.push(optionEdit);
    }

    this.actions[0].items.push(optionDownload);
  }

  versionChanger($event) {
    this.newVersionEvent.emit($event.value);
  }

  private initEditing() {
    if (this.showEditButton) {
      if (this.isFederal) {
        this.message.add({
          severity: 'warn',
          detail: 'Вы не можете редактировать данный справочник',
        });
      } else {
        this.checkRefBookStatus();
      }
    } else {
      this.message.add({
        severity: 'warn',
        detail: 'Вы не можете редактировать данный справочник',
      });
    }
  }

  showPassport() {
    this.passport.open(PassportComponent, {
      header: 'Паспорт справочника',
      width: '70%',
    });
  }

  checkRefBookStatus() {
    const rBookStatus$ = this.refBookEditor.initEditing(this.rBookCode).pipe(share());

    rBookStatus$.pipe(filter(({ status }) => status === 'new')).subscribe(status =>
      this.router.navigateByUrl(
        `/${this.refBookRegionStatus}/${this.rBookCode}/edit`
      ));

    const dialogResult$ = rBookStatus$.pipe(
      filter(({ status }) => status === 'continue'),
      tap(({ tempTableCode }) => this.tempTableName = tempTableCode),
      switchMap(response => {
        this.ref = this.dialog.open(ConfirmationComponent, {
          width: '40%',
          header: 'Справочник уже редактируется',
        });
        return this.ref.onClose;
      }),
      share(),
    );

    dialogResult$.pipe(
      filter(result => result === 'continue')
    ).subscribe(result =>
      this.router.navigateByUrl(
        `/${this.refBookRegionStatus}/${this.rBookCode}/edit`
      ));

    dialogResult$.pipe(
      filter(result => result === 'cancel'),
      switchMap(result => this.refBookEditor.deleteTempTable(this.tempTableName)),
    ).subscribe(_ => this.initEditing());
  }

  private downloadVersion() {
    this.ref = this.passport.open(DownloadVersionComponent, {
      header: 'Загрузить версию',
      width: '40%',
    });
    this.ref.onClose.subscribe((result) => {
      if (result === 'success') {
        this.message.add({
          severity: 'success',
          summary: 'Справочник',
          detail: 'Справочник успешно обновлен',
        });
      }
    });
  }

  private downloadRefBook() {
    this.ref = this.passport.open(DownloadRefbookComponent, {
      header: 'Скачать справочник',
      width: '40%',
      data: {
        name: this.refbookName,
      },
    });
  }
}
