import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/User.service';
import { Observable } from 'rxjs';
import { User } from '../../../model/user';
import { UserCardComponent } from '../shared/user-card/user-card.component';
import { CreatUserComponent } from '../shared/creat-user/creat-user.component';
import { delay } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  providers: [DialogService, MessageService],
})
export class UserListComponent implements OnInit {
  userList$: Observable<User[]>;
  userEdit$: DynamicDialogRef;
  userCreate$: DynamicDialogRef;

  constructor(
    private getUserList: UserService,
    private showCard: DialogService,
    private showMessage: MessageService
  ) {}

  ngOnInit(): void {
    this.userList$ = this.getUserList.getUserList();
  }

  showUserCard(guid: string) {
    this.userEdit$ = this.showCard.open(UserCardComponent, {
      data: {
        isCreate: true,
        guid,
        own: true,
      },
      header: 'Карточка пользователя',
      width: '70%',
    });

    this.userEdit$.onClose.subscribe((message) => {
      switch (message) {
        case 'deleted':
          this.showMessage.add({
            severity: 'success',
            summary: 'Пользователь удалён',
          });
          break;
        case 'saved':
          this.showMessage.add({
            severity: 'success',
            summary: 'Пользователь сохранён',
          });
          break;
      }
      this.userList$ = this.getUserList.getUserList().pipe(delay(1000));
    });
  }

  createUser() {
    this.userCreate$ = this.showCard.open(CreatUserComponent, {
      header: 'Создание нового пользователя',
      width: '70%',
    });

    this.userCreate$.onClose.subscribe(
      (message) => {
        this.showMessage.add({
          severity: 'success',
          summary: `Пользователь ${message.lastname} ${message.firstname} успешно создан`,
        });
      },
      (error) => {
        this.showMessage.add({
          severity: 'error',
          summary: error.message,
        });
      },
      () => (this.userList$ = this.getUserList.getUserList().pipe(delay(1000)))
    );
  }
}
