<h4 class="">{{rBookName}}</h4>
<div class="main-container">
  <div class="">
    Доступные версии:
  </div>
  <div class="main-container"><p-dropdown
    style="position: absolute; right: 1px; padding-right: 15px;"
    [options]="versionList"
    [(ngModel)]="selectedVersion"
    [class.ng-dirty]="unchosen"
    [class.ng-invalid]="unchosen"
    (onChange)="unchosen = false;"
    placeholder="выбрать версию"
  ></p-dropdown></div>
</div>
<div class="button-container">
  <p-button label="Отмена" (click)="cancelDownload()"></p-button>
  <p-button label="Скачать" (click)="downloadRefBook()"></p-button>
</div>
<p-toast></p-toast>
