import {Component, OnInit, ViewChild} from '@angular/core';
import { GetRefbookService } from '../../services/get-refbook.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map, share, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DictionaryView } from '../../model/dictionaryView';
import { Reference } from '../../model/reference';
import { RawDictionary } from '../../model/rawDictionary';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UserAuthService } from '../../services/user-auth.service';
import { CurrentRefbookService } from '../../services/current-refbook.service';
import { Version } from 'src/app/model/versions';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Paginator} from "primeng/paginator";

@Component({
  selector: 'app-refbook',
  templateUrl: './refbook.component.html',
  styleUrls: ['./refbook.component.css'],
})
export class RefbookComponent implements OnInit {
  @ViewChild('p') paginator: Paginator;
  rows: DictionaryView;
  params: string;
  array: [];
  columns: RawDictionary;
  dictName: Reference[];
  filterSwitch: boolean;
  versionList$: Observable<string[]>;
  rBookName: string;
  version: string;
  limit = 10;
  offset = 0;
  totalRecords: number;
  breadcrumb;
  filterForm: FormGroup;
  formApplier: Version = {};
  showPreloader: boolean;
  isFederal: boolean;
  private refBookGroup: string;
  showEditButton: boolean;
  private refbookOid: string;
  public first;

  constructor(
    private getDict: GetRefbookService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: UserAuthService,
    private activeRefbookService: CurrentRefbookService,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
  ) {
    this.showEditButton = this.authService.userGroups === [];
    this.refbookOid = this.route.snapshot.params.oid;
    this.rBookName = this.route.snapshot.params.rbookCode;
  }

  ngOnInit(): void {
    this.isFederal = this.route.snapshot.url[0].path === 'federal';
    this.showPreloader = true;
    this.refBookGroup = this.activeRefbookService.activeGroup;
    this.activeRefbookService.refbookOid = this.refbookOid;

    this.filterForm = new FormGroup({
      filterFormArray: new FormArray([]),
    });

    let rBookName$: Observable<string>;

    if (this.isFederal) {
      rBookName$ = this.getDict.getDictByOid(this.refbookOid).pipe(
        map(dict => {
          const { tableName } = dict;
          this.rBookName = tableName;
          this.activeRefbookService.rBookName = tableName;
          this.getVersionsList(tableName);
          return tableName;
        }),
        share(),
      );
    } else {
      this.getDict.getDict(this.rBookName).subscribe(dict => this.activeRefbookService.rBookName = dict.tableName);

      rBookName$ = of(this.rBookName).pipe(share());

      this.getVersionsList(this.rBookName);
    }

    rBookName$.pipe(
      switchMap(name => this.getDict.getDict(this.rBookName)),
      finalize(() => this.showPreloader = false),
    ).subscribe(columns => this.getTableHeader(columns), error => {
      console.error(error.error.message);
      this.redirectToMain();
    });

    /*rBookName$.pipe(
      switchMap(name => this.getDict
        .getDictInfo(
          this.rBookName,
          this.limit,
          this.offset,
          this.formApplier
        ))
    ).subscribe((dict) => {
      this.rows = dict;
      this.totalRecords = dict.rowCount;
    });*/

    if (this.authService.userGroups !== undefined) {
      if (this.authService.userGroups.length !== 0) {
        this.authService.userGroups.forEach((group) => {
          if (group.name === this.refBookGroup) {
            this.showEditButton = true;
          }
        });
      } else {
        this.showEditButton = true;
      }
    } else {
      this.showEditButton = false;
    }

    this.showPreloader = true;
    this.first = 0;
  }

  private getTableHeader(columns: RawDictionary) {
    this.columns = columns;
    // задаем имя в цепочке навигации
    this.breadcrumb =  {customText: this.toUpCase(this.columns.name)};
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(this.breadcrumb);

    columns.fieldMappingList.forEach((column) => {
      this.getFilterFormArray.push(
        new FormGroup({
          inputFieldName: new FormControl(column.fieldName),
          inputType: new FormControl(column.fieldType),
          inputValue: new FormControl(),
        })
      );
    });
  }

  toUpCase(str: string): string{
    const subString = 'российской федерации';
    const newStr  = str.includes(subString) ? str.slice(0, str.length - subString.length) + 'Российской Федерации' : str;
    return newStr[0].toUpperCase() + newStr.slice(1);
  }
  private getVersionsList(name: string) {
    this.versionList$ = this.getDict.getVersionList(name).pipe(
      tap((versions) => {
        versions.reverse();
        this.formApplier.version = versions[0];
        this.version = versions[0];
      })
    );
    // это надо переписать, этот метод позваляет отображать не записи всех версий, а конкретной версии
    this.getDict.getVersionList(this.rBookName).subscribe((version) => {
      this.getDict
        .getDictInfo(
          this.rBookName,
          this.limit,
          this.offset,
          {version: version[version.length - 1]}
        )
        .subscribe((dict) => {
          console.log(dict);
          this.rows = dict;
          this.totalRecords = dict.rowCount;
        });
    });
  }

  get getFilterFormArray() {
    return this.filterForm.get('filterFormArray') as FormArray;
  }

  filterSwitcher() {
    this.filterSwitch = !this.filterSwitch;
  }

  changeVersion(version: string, rBookName: string) {
    this.version = version;
    // @ts-ignore
    this.formApplier.version = version;
    this.getDict
      .getDictInfo(
        this.activeRefbookService.rBookName,
        this.limit,
        this.offset,
        this.formApplier
      )
      .subscribe((dict) => {
        this.rows = dict;
        this.totalRecords = dict.rowCount;
      });
  }

  applyFilters(event: Event) {
    for (const fieldName in this.getFilterFormArray.value) {
      if (this.getFilterFormArray.value[fieldName].inputValue) {
        const inputName = this.getFilterFormArray.value[fieldName]
          .inputFieldName;
        this.formApplier[inputName] = this.getFilterFormArray.value[
          fieldName
          ].inputValue;
      }
    }
    // @ts-ignore
    this.formApplier.version = this.version;
    this.getDict
      .getDictInfo(this.rBookName, this.limit, 0, this.formApplier)
      .subscribe((dict) => {
        this.rows = dict;
        this.totalRecords = dict.rowCount;
        this.paginator.changePageToFirst(event);
      });
  }

  clearFilter(fieldName: string, fieldNumber: number) {
    // @ts-ignore
    this.getFilterFormArray.controls[fieldNumber].controls.inputValue.reset();
    delete this.formApplier[fieldName];
    // tslint:disable-next-line:no-shadowed-variable
    for (const fieldName in this.getFilterFormArray.value) {
      if (this.getFilterFormArray.value[fieldName].inputValue) {
        const inputName = this.getFilterFormArray.value[fieldName]
          .inputFieldName;
        this.formApplier[inputName] = this.getFilterFormArray.value[
          fieldName
          ].inputValue;
      }
    }
    // @ts-ignore
    this.formApplier.version = this.version;
    this.getDict
      .getDictInfo(this.rBookName, this.limit, 0, this.formApplier)
      .subscribe((dict) => {
        this.rows = dict;
        this.totalRecords = dict.rowCount;
      });
  }

  private redirectToMain() {
    this.router.navigateByUrl('/federal');
  }

  setPage(event: any): void {
    this.limit = event.rows;
    const currentPage = event.first / event.rows + 1;
    this.offset = (currentPage - 1) * event.rows;
    this.getDict
      .getDictInfo(
        this.activeRefbookService.rBookName,
        this.limit,
        this.offset,
        this.formApplier
      )
      .subscribe((dict) => {
        this.rows = dict;
        this.totalRecords = dict.rowCount;
      });
  }
}
