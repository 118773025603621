import { Injectable } from '@angular/core';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { UserGroup } from '../model/userGroup';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  // tslint:disable-next-line:variable-name
  _env: Environment = environment;
  userRole: string;
  userName: string;
  userGroups: UserGroup[] = [];
  user: User;
  userIdRefMu: any;

  constructor(private http: HttpClient) {
    this.getCurrentUser().subscribe((user) => {
      if (user) {
        this.getUserRole(user);
        this.getUserName(user);
        this.getUserGroup(user.groups);
        this.getIdRefMu(user);
        this.user = user;
      }
    });
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(
      `${this._env.baseUrl}/su_nsi/userDetails/currentUser`
    );
  }

  private getUserRole(user: User) {
    this.userRole = user.role.name;
  }

  private getUserGroup(groups: UserGroup[]) {
    groups.forEach((x) => {
      this.userGroups.push({ name: x.name, id: x.id });
    });
  }

  isAdmin() {
    return this.userRole === 'ROLE_ADMIN';
  }

  isAdminMo() {
    return this.userRole === 'ROLE_ADMIN_MO';
  }

  isManager() {
    return this.userRole === 'ROLE_MANAGER' || this.userRole === 'ROLE_ANALYST';
  }

  isAuth() {
    return !!this.userRole;
  }

  getUserName(user: User) {
    this.userName = user.lastname + ' ' + user.firstname;
  }

  isFederalGroups() {
    return this.userGroups.some((group) => group.id === 5);
  }

  getIdRefMu(user) {
    this.userIdRefMu = user.idMos;
  }
}
