import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly host = environment.login;
  env: Environment = environment;

  constructor(
    private readonly http: HttpClient,
    @Inject(DOCUMENT) public readonly document: Document
  ) {}

  loginCAS() {
    const localUrl = this.getUrl();
    const casUrl = `https://cas-test.hostco.ru/cas/login?service=${localUrl}`;
    window.location.href = `${encodeURI(casUrl)}`;
    // return this.http.get<any>(this.host + '/login').subscribe(({ url }) => {
    //   });
  }

  login(ticket) {
    return this.http.get<string>(
      this.host + `/login/cas?ticket=${ticket}&serviceUrl=${this.getUrl()}`,
      {
        withCredentials: true,
      }
    );
  }

  loginNsi(): Observable<any> {
    return this.http.get<any>(this.env.login + `/login`);
  }

  logout() {
    return this.http
      .get<any>(this.env.login + `/logout`, {
        withCredentials: true,
      })
      .subscribe(({ url }) => {
        this.document.location.href = `${encodeURI(url)}`;
      });
  }

  getCasUrl() {
    const serviceUrl = this.getUrl();
    this.loginNsi().subscribe((url) => {
      document.location.href = `${url.url}?service=${serviceUrl}`;
    });
  }

  getUrl() {
    return `${document.location.protocol}//${document.location.hostname}:${document.location.port}`;
  }
}
