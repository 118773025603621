<div *ngIf="!showPreloader" class="table wrapper">
  <h3>{{toUpCase(columns.name)}}</h3>
  <app-dict-settings
    (filterSwitcher)="filterSwitcher()"
    [versionList]="versionList$"
    (newVersionEvent)="changeVersion($event, rBookName)"
    [isFederal]="isFederal"
    [showEditButton]="showEditButton"
    [refbookName]="columns.name"
  ></app-dict-settings>
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters($event)">
    <div *ngIf="rows" class="table-x-scroll">
      <p-table
        [value]="rows?.dictList"
        [columns]="columns.fieldMappingList"
        [totalRecords]="rows?.rowCount"
        [rowsPerPageOptions]="[10, 30, 50]"
        [rowHover]="true"
        [rows]="10"
        [loading]="showPreloader"
        [(first)]="first"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              <ng-container *ngIf="col.alias && col.alias.length > 1; else elseBlock">
                <div>{{col.alias}}</div>
              </ng-container>
              <ng-template #elseBlock>
                {{col.fieldName}}
              </ng-template>
            </th>
          </tr>
          <tr *ngIf="filterSwitch" formArrayName="filterFormArray">
            <th *ngFor="let col of getFilterFormArray.controls; index as i">
                <span class="p-input-icon-right" [formGroupName]="i">
                  <i
                    class="pi pi-times"
                    style="cursor: pointer;"
                    (click)="clearFilter(col.value.inputFieldName, i)"
                  ></i>
                  <input
                    *ngIf="col.value.inputType === 'STRING'"
                    type="text"
                    pInputText
                    formControlName="inputValue"
                    class="p-inputtext p-component"
                  />
                  <input
                    *ngIf="col.value.inputType === 'NUMBER'"
                    type="number"
                    pInputText
                    formControlName="inputValue"
                    class="p-inputtext p-component"
                  />
                  <input
                    *ngIf="col.value.inputType === 'DATE'"
                    type="date"
                    pInputText
                    max="2200-12-31"
                    formControlName="inputValue"
                    class="p-inputtext p-component"
                  />
                </span>
              <input class="form-submit-button" type="submit"/>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-columns="columns">
          <tr [pSelectableRow]="row">
            <td *ngFor="let col of columns">
              {{row[col.fieldName]}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorright">
          <span>Всего записей: {{totalRecords}}</span>
        </ng-template>
      </p-table>
      <div class="pagination-wrapper">
        <div class="pagination-main">
          <p-paginator
            #p
            [rows]="10"
            [totalRecords]="totalRecords"
            (onPageChange)="setPage($event)"
            currentPageReportTemplate="{currentPage} из {totalPages}"
            [rowsPerPageOptions]="[10,30,50]"
            [showCurrentPageReport]="true"
          >
          </p-paginator>
        </div>
        <div class="pagination-total">
          <span>Всего записей: {{totalRecords}}</span>
        </div>
      </div>
    </div>
  </form>
</div>
<p-progressSpinner *ngIf="showPreloader"></p-progressSpinner>
