import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserAuthService } from '../../services/user-auth.service';
import { UserCardComponent } from '../../admin/components/shared/user-card/user-card.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { filter, map, finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
  providers: [DialogService],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  userName: string;
  subscription: Subscription = new Subscription();

  constructor(
    public authService: UserAuthService,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.pipe(
        map(params => {
          const ticket = params.ticket;
          const xch = new XMLHttpRequest();
          return ticket;
        }),
        filter(ticket => !!ticket),
        switchMap(ticket => this.loginService.login(ticket)),
        finalize(this.clearTicket),
      ).subscribe({ error: e => console.error(e) })
    );
    this.route.queryParams.subscribe((params) => {
      const ticket = params.ticket;
      if (document.location.href.includes(ticket)) {
        this.clearTicket();
      }
    });
  }

  showCurrentUserCard() {
    this.dialog.open(UserCardComponent, {
      data: {
        isCreate: true,
        guid: this.authService.user.guid,
        own: false,
      },
      header: 'Карточка пользователя',
      width: '70%',
    });
  }

  getCasUrl() {
    const serviceUrl = this.getUrl();
    this.loginService.loginNsi().subscribe((url) => {
      document.location.href = `${url.url}?service=${serviceUrl}`;
    });
  }

  getUrl() {
    return `${document.location.protocol}//${document.location.hostname}:${document.location.port}`;
  }

  private clearTicket() {
    document.location.href = this.getUrl();
  }

  logout() {
    this.loginService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
