<div class="wrapper">
  <p-button (click)="filterSwitcher()"><i class="pi pi-filter"></i></p-button>
  <form *ngIf="columns" [formGroup]="filterForm" (ngSubmit)="applyFilters()">
    <p-table
      [value]="rows"
      [columns]="columns?.fieldMappingList"
      [paginator]="true"
      currentPageReportTemplate="{currentPage} из {totalPages}"
      [rows]="10"
      [scrollable]="true"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [class.fieldType] />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns?.fieldMappingList">
            {{ col.fieldName }}
          </th>
        </tr>
        <tr *ngIf="filterSwitch" formArrayName="filterFormArray">
          <th *ngFor="let col of getFilterFormArray.controls; index as i">
            <span class="p-input-icon-right" [formGroupName]="i">
              <i
                class="pi pi-times"
                style="cursor: pointer;"
                (click)="clearFilter(col.value.inputFieldName, i)"
              ></i>
              <input
                *ngIf="col.value.inputType === 'STRING'"
                type="text"
                pInputText
                formControlName="inputValue"
                class="p-inputtext p-component"
              />
              <input
                *ngIf="col.value.inputType === 'NUMBER'"
                type="number"
                pInputText
                formControlName="inputValue"
                class="p-inputtext p-component"
              />
            </span>
            <input class="form-submit-button" type="submit" />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-body let-columns="columns">
        <tr [pSelectableRow]="body" (click)="editRow(body)">
          <td class="table-column" *ngFor="let col of columns">
            <span>{{ body[col.fieldName] }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <p-button
          (click)="addRow()"
          type="button"
          icon="pi pi-plus"
          styleClass="p-button-text"
        ></p-button>
      </ng-template>
    </p-table>
  </form>
</div>

<p-dialog [visible]="showDialog" [style]="{ width: '450px' }">
  <ng-template pTemplate="content">
    <div class="p-field" *ngFor="let field of columns.fieldMappingList">
      <label style="display: flex; flex-direction: column;">
        {{ field.fieldName }}
        <textarea
          type="text"
          pInputTextarea
          autoResize="autoResize"
          [(ngModel)]="newObject[field.fieldName]"
          (change)="isValueChange($event)"
        ></textarea>
      </label>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      *ngIf="!createRow"
      pButton
      pRipple
      label="Удалить"
      icon="pi pi-check"
      class="p-button-text"
      (click)="deleteRow(newObject)"
    ></button>
    <button
      pButton
      pRipple
      label="Отмена"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      *ngIf="createRow"
      pButton
      pRipple
      label="Добавить"
      icon="pi pi-check"
      class="p-button-text"
      (click)="saveNewRow()"
    ></button>
    <button
      *ngIf="!createRow || isChange"
      pButton
      pRipple
      label="Сохранить"
      icon="pi pi-check"
      class="p-button-text"
      (click)="saveExistRow()"
    ></button>
  </ng-template>
</p-dialog>
<div class="wrapper">
  <button pButton label="Отмена" (click)="goBack()"></button>
  <button pButton label="Сохранить" (click)="saveDict()" [disabled]="this.isChange"></button>
</div>
<p-toast></p-toast>
