import {Component, OnInit, ViewChild} from '@angular/core';
import { GetTablesService } from '../../services/get-tables.service';
import { FormGroup } from '@angular/forms';
import { CreateFederalRefbookComponent } from '../../admin/components/shared/create-federal-refbook/create-federal-refbook.component';
import { UserAuthService } from '../../services/user-auth.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CurrentRefbookService } from '../../services/current-refbook.service';
import { RefBookView } from 'src/app/model/reference';
import {Table} from 'primeng/table';
import {Paginator} from 'primeng/paginator';

@Component({
  selector: 'app-federal',
  templateUrl: './federal.component.html',
  styleUrls: ['./federal.component.css'],
  providers: [DialogService, DynamicDialogRef, MessageService],
})
export class FederalComponent implements OnInit {
  @ViewChild('p') paginator: Paginator;
  updateParams: RefBookView[];
  filterName: string;
  private dialog: DynamicDialogRef;
  totalRecords: number;
  first: number;
  limit = 10;
  offset = 1;
  filterElem: any;
  constructor(
    private getTable: GetTablesService,
    private dialogService: DialogService,
    private message: MessageService,
    public userService: UserAuthService,
    public activeRefbookService: CurrentRefbookService,
  ) { }

  ngOnInit(): void {
    this.getTable.getFederalTable(1, this.limit, this.offset).subscribe((rows) => {
      this.updateParams = rows.refBookViewList;
      this.totalRecords = rows.rowCount;
    });
    this.first = 0;
  }

  lazyLoading($event: LazyLoadEvent) {}

  dateFormat(str: string): any {
   return `${('0' + new Date(str).getDate()).slice(-2)}.${('0' + (new Date(str).getMonth() + 1)).slice(-2)}.${new Date(str).getFullYear()}`;
  }

  nameFormat(str: string): string{
    const subString = 'российской федерации';
    return str.includes(subString) ? str.slice(0, str.length - subString.length) +  + 'Российской Федерации' : str;
  }

  filterApply($event: FormGroup) {
    this.filterElem = $event;
    this.getTable
      .getFederalTable(
        1,
        this.limit,
        this.offset,
        $event.value.refBookName,
        $event.value.refBookId,
        $event.value.refBookGroup,
        $event.value.refBookStatus
      )
      .subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
        this.paginator.changePageToFirst($event);
      });
  }

  createRefbook() {
    this.dialog = this.dialogService.open(CreateFederalRefbookComponent, {
      width: '70%',
      header: 'Создание справочника',
    });
    this.dialog.onClose.subscribe((response) => {
      if (response) {
        this.message.add({
          severity: 'success',
          summary: 'Создание справочника',
          detail: 'Справочник успешно создан',
        });
      }
    });
  }

  setPage(event: any): void {
    if (this.filterElem !== undefined){
      this.getTable.getFederalTable(
        1,
        event.rows,
        (event.first / event.rows + 1),
        this.filterElem.value.refBookName,
        this.filterElem.value.refBookId,
        this.filterElem.value.refBookGroup,
        this.filterElem.value.refBookStatus,
      ).subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
      });
    }else{
      this.getTable.getFederalTable(
        1,
        event.rows,
        (event.first / event.rows + 1)
      ).subscribe((rows) => {
        this.updateParams = rows.refBookViewList;
        this.totalRecords = rows.rowCount;
      });
    }
  }
}
