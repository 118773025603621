<div class="header">
  <div class="menu-item" routerLink="/">СУ НСИ</div>
  <div class="menu-item dropdown-main">
    Справочники <i class="pi pi-angle-down"></i>
    <div class="dropdown-container dropdown-container--reference">
      <div class="dropdown-item" [routerLink]="['/federal']">
        Федеральный
      </div>
      <div class="dropdown-item" [routerLink]="['/regional']">
        Региональный
      </div>
    </div>
  </div>
  <div *ngIf="authService.isAdmin()" class="menu-item dropdown-main">
    Администрирование<i class="pi pi-angle-down"></i>
    <div class="dropdown-container dropdown-container--admin">
      <div *ngIf="authService.isAdmin()" class="dropdown-item" [routerLink]="['/admin/users']">
        Пользователи
      </div>
      <div *ngIf="authService.isAdmin()" class="dropdown-item" [routerLink]="['/admin/groups']">
        Группы
      </div>
    </div>
  </div>
  <div *ngIf="authService.isAdminMo() || authService.isAdmin()" class="menu-item"
       [routerLink]="['/certificates']"
  >
    ЭЦП МО
  </div>
  <div class="menu-item">Справка</div>
  <a
    *ngIf="!authService.isAuth(); else elseBlock"
    class="menu-item"
    (click)="getCasUrl()"
  >
    Войти
  </a>
  <ng-template #elseBlock>
    <div class="menu-item dropdown-main">
      {{authService.userName}} <i class="pi pi-angle-down"></i>
      <div class="dropdown-container dropdown-container--reference dropdown-container--login">
        <div class="dropdown-item" (click)="showCurrentUserCard()">
          Профиль
        </div>
        <div class="dropdown-item" (click)="logout()">
          Выйти
        </div>
      </div>
    </div>
<!--    <div (click)="showCurrentUserCard()" class="menu-item profile">-->
<!--      Профиль-->
<!--    </div>-->
  </ng-template>
</div>
<app-ng-dynamic-breadcrumb></app-ng-dynamic-breadcrumb>
<router-outlet></router-outlet>
