import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../model/environments';
import { User } from '../model/user';
import { environment } from '../../environments/environment';
import { CreateUserAnswer } from '../model/createUserAnswer';
import {MedicalDepartments} from "../model/medicalDepartments";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  _env: Environment = environment;

  constructor(private http: HttpClient) {}

  getUserList(): Observable<User[]> {
    return this.http.get<User[]>(`${this._env.baseUrl}/su_nsi/user/list`);
  }

  getUser(guid: string): Observable<User> {
    return this.http.get<User>(`${this._env.baseUrl}/su_nsi/user/${guid}`);
  }

  saveUserCardInfo(guid: string, userInfo: {}): Observable<CreateUserAnswer> {
    return this.http.put<CreateUserAnswer>(
      `${this._env.baseUrl}/su_nsi/user/${guid}`,
      userInfo
    );
  }

  deleteUser(guid: string): Observable<any> {
    return this.http.delete(`${this._env.baseUrl}/su_nsi/user/${guid}`);
  }

  createUser(userInfo: {}) {
    return this.http.post(`${this._env.baseUrl}/su_nsi/user`, userInfo);
  }

  getMedicalDepartments(): Observable<MedicalDepartments[]> {
    return this.http.get<MedicalDepartments[]>(`${this._env.baseUrl}/su_nsi/certificates/medicalDepartments`);
  }
}
