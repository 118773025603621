import { Component, OnInit, OnDestroy } from '@angular/core';
import { GroupService } from '../../../services/group.service';
import { Subscription } from 'rxjs';
import { Group } from '../../../model/group';
import { CreateGroupComponent } from '../shared/create-group/create-group.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
  providers: [DialogService, MessageService, DynamicDialogRef],
})
export class GroupsComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  groupList: Group[];

  constructor(
    private group: GroupService,
    private createCard: DialogService,
    private showMessage: MessageService,
    private dialog$: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.sub.add(this.group.getGroupList().subscribe((groups) =>
      this.groupList = groups
    ));
  }

  createGroup() {
    this.dialog$ = this.createCard.open(CreateGroupComponent, {
      width: '70%',
      header: 'Создать группу',
    });
    this.sub.add(this.dialog$.onClose.pipe(
      filter(answer => answer === 'saved'),
      switchMap(_ => {
        this.showMessage.add({
          severity: 'success',
          summary: 'Группа сохранена',
        });
        return this.group.getGroupList();
      })
    ).subscribe((groups) => this.groupList = groups));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
