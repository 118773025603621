<div class="wrapper">
  <h3>ЭЦП МО</h3>
  <div class="main-block">
    <div class="" style="width: 50%; margin: 20px 0 0;">
      <p-dropdown
        (onChange)="onFilterChanged()"
        [options]="getMoList"
        [(ngModel)]="filter.mu"
        [style]="{ width: '100%' }"
        optionLabel="nameMu"
        placeholder="Медицинская организация"
        [showClear]="true"
      ></p-dropdown>
      <input
        style="margin-top: 10px;"
        (change)="onFilterChanged()"
        id="checkActual"
        type="checkbox"
        [(ngModel)]="filter.actual"
      />
      <label class="check-filter-label nsi-font" for="checkActual"> Актуальные</label></div>
    <div class="flex right-side">
      <button
        style="height: 46px"
        pButton
        *ngIf="filter.mu && filter.mu.idMu"
        type="button"
        (click)="onAddCertificate()"
        label="Добавить сертификат"
      >
      </button>
    </div>
  </div>

  <p-table [value]="certificates">
    <ng-template pTemplate="header">
      <tr>
        <th class="col-sm-5">Медорганизация</th>
        <th class="col-sm-2">Сертификат</th>
        <th class="col-sm-2">Кем выдан</th>
        <th class="col-sm-2">Кому выдан</th>
        <th class="col-sm-1">От</th>
        <th class="col-sm-1">До</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-cert>
      <tr>
        <td>{{cert.nameMu}}</td>
        <td><a
          [href]="downloadURL + cert.certId"
          [download]="cert.fileName"
        >{{ cert.fileName }}</a
        ></td>
        <td>{{cert.publisher}}</td>
        <td>{{cert.subject}}</td>
        <td>{{ cert.validFrom | date: 'dd-MM-yyyy' }}</td>
        <td>{{ cert.validTo | date: 'dd-MM-yyyy' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  *ngIf="filter.mu && filter.mu.idMu"
  header="Создание сертификата"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
>
  <p-fileUpload
    name="file"
    [url]="uploadURL"
    (onBeforeUpload)="onBeforeUpload($event)"
    [multiple]="false"
    accept=".cer"
    [chooseLabel]="'Выбрать файл'"
    [uploadLabel]="'Отправить'"
    [cancelLabel]="'Отмена'"
    (onUpload)="onUpload()"
    (onError)="onError($event)"
    [showCancelButton]="false"
  >
    <ng-template pTemplate="toolbar">
      <button
        pButton
        type="button"
        (click)="onCancelUpload()"
        label="Отмена"
      >
      </button>
    </ng-template>
  </p-fileUpload>
</p-dialog>
<p-toast></p-toast>
