import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Reference } from '../model/reference';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetTablesService {
  // tslint:disable-next-line:variable-name
  _env: Environment = environment;

  constructor(private http: HttpClient) {}

  getFederalTable(
    isFederal?: number,
    size?: number,
    page?: number,
    rBookName?: string,
    refBookOid?: string,
    groupId?: any,
    statusId?: any
  ): Observable<Reference> {
    return this.http.post<any>(`${this._env.baseUrl}/su_nsi/dicts/all`, {
      filter: {
        groupId,
        isFederal,
        size,
        page,
        tableName: null,
        rbookCode: null,
        rbookName: rBookName,
        version: null,
        schemaName: null,
        oid: refBookOid,
        statusId,
      },
    });
  }

  getFederalCodeByRbookCode(oid): Observable<any> {
    return this.http.get<any>(`${this._env.baseUrl}/su_nsi/dicts/version/fnsi/${oid}`);
  }

  checkVersion(rbookCode, version): Observable<any> {
    return this.http.post<any>(
      `${this._env.baseUrl}/su_nsi/dicts/data/existversion?rbookCode=${rbookCode}&version=${version}`,
      {}
    );
  }

  getRegionalTable(
    isFederal?: number,
    size?: number,
    page?: number,
    rBookName?: string,
    refBookCode?: string,
    groupId?: number,
    statusId?: number
  ): Observable<Reference> {
    return this.http.post<any>(`${this._env.baseUrl}/su_nsi/dicts/all`, {
      filter: {
        groupId,
        isFederal,
        size,
        page,
        tableName: null,
        rbookCode: refBookCode,
        rbookName: rBookName,
        version: null,
        schemaName: null,
        oid: null,
        statusId,
      },
    });
  }
}
