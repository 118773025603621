import { Component, OnInit } from '@angular/core';
import { GetRefbookService } from '../../../services/get-refbook.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { CurrentRefbookService } from '../../../services/current-refbook.service';

@Component({
  selector: 'app-download-refbook',
  templateUrl: './download-refbook.component.html',
  styleUrls: ['./download-refbook.component.css'],
  providers: [MessageService],
})
export class DownloadRefbookComponent implements OnInit {
  rBookName: string;
  versionList: string[] = ['Все версии'];
  selectedVersion: string | undefined;
  private rBookCode: string;
  private blobData: any;
  unchosen: boolean;

  constructor(
    private getRefbookService: GetRefbookService,
    private ref: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private message: MessageService,
    private currentRefbook: CurrentRefbookService
  ) { }

  ngOnInit(): void {
    this.unchosen = false;
    this.rBookName = this.ref.data.name;
    this.rBookCode = this.currentRefbook.rBookName;
    this.getRefbookService
      .getVersionList(this.rBookCode)
      .subscribe((versions) => {
        versions.forEach((version) => {
          this.versionList.push(version);
        });
      });
  }

  downloadRefBook() {
    if (this.selectedVersion === undefined) {
      return (this.unchosen = true);
    }
    if (this.selectedVersion === 'Все версии') {
      this.selectedVersion = '';
    }
    this.getRefbookService
      .exportRefbook(this.rBookCode, this.selectedVersion)
      .subscribe(
        (refBook) => {
          this.blobData = refBook.body;
        },
        (error) => {
          console.log(error);
          this.message.add({
            summary: 'Ошибка',
            severity: error.error.error,
          });
        },
        () => {
          this.downloadFile(this.blobData);
          this.dialog.close();
        }
      );
  }

  cancelDownload() {
    this.dialog.close();
  }

  downloadFile(data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, this.rBookName + '.xlsx');
  }
}
