import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Group } from '../model/group';
import {Status} from "../model/status";

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  _env: Environment = environment;

  constructor(private http: HttpClient) {}

  getGroupList(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this._env.baseUrl}/su_nsi/group`);
  }

  createNewGroup(value: Group): Observable<any> {
    return this.http.post<any>(`${this._env.baseUrl}/su_nsi/group`, value);
  }

  getStatus(): Observable<Status[]> {
    return this.http.get<Status[]>(`${this._env.baseUrl}/su_nsi/dicts/status_list`);
  }
}
