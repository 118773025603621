<p-toast></p-toast>
<div class="wrapper">
  <h3 class="position-absolute">Пользователи</h3>
  <header>
    <p-button label="Создать пользователя" (click)="createUser()"></p-button>
  </header>
  <main>
    <p-table
      [value]="userList$ | async"
      [paginator]="true"
      currentPageReportTemplate="{currentPage} из {totalPages}"
      [rows]="10"
      class="table-container"
      [rowHover]="true"
      [rowsPerPageOptions]="[5, 10, 15]"
      styleClass="custom-table"
      ><ng-template pTemplate="header">
        <tr class="table-header">
          <th>Логин</th>
          <th>ФИО</th>
          <th>Организация</th>
          <th>Роль</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-table>
        <tr class="table-body" (click)="showUserCard(table.guid)">
          <td class="table-column">{{ table.login }}</td>
          <td class="table-column">
            {{ table.lastname }} {{ table.firstname }} {{ table.middlename }}
          </td>
          <td class="table-column">{{ table.organisation }}</td>
          <td class="table-column">
            <div class="inner-table-block">
              {{ table.role.alias }}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </main>
</div>
