import { Injectable } from '@angular/core';
import { Environment } from '../model/environments';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateResponse } from '../model/createResponse';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  env: Environment = environment;

  constructor(private http: HttpClient) { }

  uploadExcelFile(selectedFile: File) {
    const formData = new FormData();
    formData.append('file', selectedFile);
    return this.http.post(`${this.env.baseUrl}/su_nsi/upload`, formData);
  }

  getColumns(fileName: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.env.baseUrl}/su_nsi/files/columns?path=${fileName}`
    );
  }

  sendRefBook(value): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(
      `${this.env.baseUrl}/su_nsi/files/create`,
      value
    );
  }

  loadDictInfo(fileName: string, version: string, rbookcode: string) {
    return this.http.get(
      `${this.env.baseUrl}/su_nsi/files/load/${rbookcode}?path=${fileName}&version=${version}`
    );
  }
}
