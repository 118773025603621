import { Component, OnInit } from '@angular/core';
import { GroupService } from '../../../../services/group.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css'],
  providers: [MessageService],
})
export class CreateGroupComponent implements OnInit {
  groupForm: FormGroup;
  constructor(
    private group: GroupService,
    private showMessage: MessageService,
    private dialog: DynamicDialogRef
  ) {
    this.groupForm = new FormGroup({
      prefix: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  createGroup() {
    this.group.createNewGroup(this.groupForm.value).subscribe(
      (answer) => {},
      (error) => {
        console.log(error);
        this.showMessage.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.error.message,
        });
      },
      () => {
        this.dialog.close('saved');
      }
    );
  }

  closeWindow() {
    this.dialog.close();
  }
}
